import React from "react";
import { Link } from "react-router-dom";
import Navigation from "./Navigaton";
import "tailwindcss/tailwind.css"; // Import Tailwind CSS styles
import Logout from "../Logout/Logout";

const Topbar = () => {
  return (
    <div className="bg-gray-100 border-b border-slate-500 p-2">
      <Logout />
    </div>
  );
};

export default Topbar;
