import React from "react";
import { useLocation } from "react-router-dom";
import defaultLogo from "../../../assets/image/canada-logo.png";

const UserDetails = () => {
  const location = useLocation();
  const { row } = location.state;

  return (
    <div className="container mx-auto mt-10">
      <div className="max-w-4xl mx-auto bg-white shadow-md rounded-xl px-8 pt-6 pb-8 mb-4">
        <h2 className="text-xl font-bold mb-4">Member Information</h2>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p>
              <span className="font-bold">Company:</span>{" "}
              {row.memberInformation.nameOfTheCompany}
            </p>
            <p>
              <span className="font-bold">Head Office:</span>{" "}
              {row.memberInformation.headOfficeAddress}
            </p>
            <p>
              <span className="font-bold">City:</span>{" "}
              {row.memberInformation.city}
            </p>
            <p>
              <span className="font-bold">Province:</span>{" "}
              {row.memberInformation.province}
            </p>
            <p>
              <span className="font-bold">Country:</span>{" "}
              {row.memberInformation.country}
            </p>
            <p>
              <span className="font-bold">Postal:</span>{" "}
              {row.memberInformation.postal}
            </p>
            <p>
              <span className="font-bold">Telephone:</span>{" "}
              {row.memberInformation.telephone}
            </p>
            <p>
              <span className="font-bold">Website:</span>{" "}
              {row.memberInformation.website}
            </p>
            <p>
              <span className="font-bold">Email:</span>{" "}
              {row.memberInformation.emailAddress}
            </p>
            <p>
              <span className="font-bold">Fax:</span>{" "}
              {row.memberInformation.fax}
            </p>
            <p>
              <span className="font-bold">Category:</span>{" "}
              {row.memberInformation.companyCategory}
            </p>
          </div>
          <div>
            <img
              src={
                row.memberInformation.companyLogo
                  ? row.memberInformation.companyLogo
                  : defaultLogo
              }
              alt="Company Logo"
              className="w-32 h-32 object-cover rounded-full mx-auto"
            />
            <p className="mt-2">
              {row.memberInformation.companyDescription
                ? row.memberInformation.companyDescription
                : "No description"}
            </p>
          </div>
        </div>
      </div>

      <div className="max-w-4xl mx-auto bg-white shadow-md rounded-xl px-8 pt-6 pb-8 mb-4">
        <h2 className="text-xl font-bold mb-4">Contact Information</h2>
        <div>
          <p>
            <span className="font-bold">Name:</span> {row.contactInfo.firstName}{" "}
            {row.contactInfo.lastName}
          </p>
          <p>
            <span className="font-bold">Address:</span>{" "}
            {row.contactInfo.currentAddress}
          </p>
          <p>
            <span className="font-bold">City:</span> {row.contactInfo.city}
          </p>
          <p>
            <span className="font-bold">Territory:</span>{" "}
            {row.contactInfo.territory}
          </p>
          <p>
            <span className="font-bold">Country:</span>{" "}
            {row.contactInfo.country}
          </p>
          <p>
            <span className="font-bold">Postal:</span> {row.contactInfo.postal}
          </p>
          <p>
            <span className="font-bold">Cell Phone:</span>{" "}
            {row.contactInfo.cellPhone}
          </p>
          <p>
            <span className="font-bold">Email:</span>{" "}
            {row.contactInfo.emailAddress}
          </p>
          <p>
            <span className="font-bold">Username:</span>{" "}
            {row.contactInfo.userName}
          </p>
        </div>
      </div>

      <div className="max-w-4xl mx-auto bg-white shadow-md rounded-xl px-8 pt-6 pb-8 mb-4">
        <h2 className="text-xl font-bold mb-4">Package Information</h2>
        <div>
          <p>
            <span className="font-bold">Package Name:</span>{" "}
            {row.packageInformation.packageId.name}
          </p>
          <p>
            <span className="font-bold">Price:</span> $
            {row.packageInformation.packageId.price}
          </p>
          <p>
            <span className="font-bold">Status:</span>{" "}
            <span className={row.status ? "text-green-600" : "text-red-600"}>
              {row.status ? "Active" : "Inactive"}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
