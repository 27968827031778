import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TableHeader from "../../components/Table/TableHeader";
import Table from "../../components/Table/Table";
import { useLocation } from "react-router-dom";
import { BiFilter } from "react-icons/bi";

const BusinessListing = ({ rows, columns, loading }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramFilter = queryParams.get("filter");
  const [searchQuery, setSearchQuery] = useState(""); // State to hold search query
  console.log(paramFilter);
  const [filterOptionIsOpen, setFilterOptionIsOpen] = useState(false);

  const [selectedRows, setSelectedRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState(rows);

  const handleFilterClick = (filter) => {
    setSearchQuery(""); // Clear search query when filter is applied
    if (filter === "") {
      setFilteredRows(rows);
    } else {
      setFilteredRows(rows.filter((row) => row.approvedByAdmin === filter));
    }
    setFilterOptionIsOpen(false);
  };

  useEffect(() => {
    // Filter rows based on search query and parameter filter
    let filteredData = rows;
    if (searchQuery !== "") {
      filteredData = filteredData.filter((row) =>
        row.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    if (paramFilter) {
      filteredData = filteredData.filter(
        (row) => row.approvedByAdmin === paramFilter
      );
    }
    setFilteredRows(filteredData);
  }, [paramFilter, rows, searchQuery]);

  return (
    <div className="px-4 pt-3">
      {/* Page Header */}
      <div className="flex flex-col sm:flex-row justify-between items-center pt-5 pb-4 sm:pb-5">
        <span className="text-xl font-semibold text-primary-main mb-2 sm:mb-0">
          Business
        </span>
        <div className="flex gap-3"></div>
      </div>
      <div className="border flex flex-col rounded bg-white">
        <div className="flex justify-between m-2">
          <form>
            <label
              htmlFor="default-search"
              className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
            >
              Search
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="search"
                id="default-search"
                className="block w-full py-3 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Search ..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                required
              />
            </div>
          </form>

          <div className="flex  p-2 flex-row-reverse ">
            <button
              onClick={() => setFilterOptionIsOpen(!filterOptionIsOpen)}
              data-bs-toggle="dropdown"
              aria-expanded="false"
              className="bg-cyan-900 shadow  px-2 h-[2rem] flex items-center rounded border"
            >
              <BiFilter className="text-2xl text-white" />
            </button>
            {filterOptionIsOpen && (
              <div
                className="dropdown bg-white mt-7 border shadow absolute"
                style={{ zIndex: "1000" }}
              >
                <ul className="dropdown-menu p-2 ">
                  <li
                    onClick={() => handleFilterClick("")}
                    className="dropdown-item px-2 py-1 cursor-pointer hover:bg-primary-main hover:text-white"
                  >
                    All
                  </li>
                  <li
                    onClick={() => handleFilterClick("approved")}
                    className="dropdown-item px-2 py-1 cursor-pointer hover:bg-primary-main hover:text-white"
                  >
                    Approved
                  </li>
                  <li
                    onClick={() => handleFilterClick("pending")}
                    className="dropdown-item px-2 py-1 cursor-pointer hover:bg-primary-main hover:text-white"
                  >
                    Pending
                  </li>
                  <li
                    onClick={() => handleFilterClick("rejected")}
                    className="dropdown-item px-2 py-1 cursor-pointer hover:bg-primary-main hover:text-white"
                  >
                    Rejected
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>

        <div className="overflow-x-auto">
          <div className="flex-grow overflow-auto">
            <Table
              rows={filteredRows}
              columns={columns}
              isCheckbox={true}
              selectedRows={selectedRows}
              onRowSelect={(selectedRows) => setSelectedRows(selectedRows)}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessListing;
