import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import FinalLogo from "../../../assets/image/FinalLogo.png";

const VerticalNavBar = ({
  toggleCollapse,
  isCollapsed,
  navigation,
  isPathEqualtoNavItem = (navItem) => false,
}) => {
  const navigate = useNavigate();
  let adminToken = localStorage.getItem("adminToken");
  const [selectedNavItemIndex, setSelectedNavItemIndex] = useState(null);
  const handleNavItemClick = (index, path) => {
    setSelectedNavItemIndex(index);
    navigate(path);
  };

  return (
    <div className="h-full py-3 overflow-auto bg-gray-200 border-r border-gray-500">
      {/* Logo & Menu Icon */}
      {!isCollapsed && (
        <div className="font-semibold py-4 text-xl">
          <img src={FinalLogo} alt="Logo" className="h-full w-[230px]" />
        </div>
      )}
      <div
        className={`flex px-3 items-center ${
          isCollapsed ? "justify-between" : "justify-between"
        }`}
      >
        {/* Logo */}

        {!isCollapsed && (
          <div className="font-semibold text-xl">
            <h1 className="text-primary-main">{`${
              adminToken ? "Admin" : "Member"
            } Portal`}</h1>
          </div>
        )}

        {/* Menu Icon */}
        <div
          onClick={toggleCollapse}
          className="flex flex-col gap-1 cursor-pointer "
        >
          <div
            className={`h-[1.5px] w-5 bg-primary-main transition-all duration-500 ${
              !isCollapsed && "origin-top-left translate-x-[1.5px]  rotate-45"
            }`}
          >
            {" "}
          </div>
          {isCollapsed && <div className={`h-[1.5px] w-5 bg-slate-500`}> </div>}
          <div
            className={`h-[1.5px] w-5 bg-primary-main transition-all duration-500 ${
              !isCollapsed && "origin-top-left translate-y-2  -rotate-45"
            }`}
          >
            {" "}
          </div>
        </div>

        {/* <div onClick={toggleCollapse} className="text-xl text-slate-500">
            <FiMenu />
          </div> */}
      </div>

      {/* Navigations */}
      <div className="px-3 py-5 flex flex-col gap-1">
        {navigation?.map((navItem, navIndex) => {
          return (
            <div
              key={navIndex}
              onClick={() => handleNavItemClick(navIndex, navItem.path)}
              className={`
                flex
                gap-3
                items-center 
                rounded 
                p-2 
                cursor-pointer  
                hover:bg-green-300
                transition-all
                duration-500
                text-primary-main
                ${isCollapsed && "justify-center"} 
                ${
                  selectedNavItemIndex === navIndex
                    ? "bg-[#033c4d] text-white font-semibold"
                    : "text-primary-main"
                }
                ${
                  isPathEqualtoNavItem(navItem)
                    ? "bg-red-50 text-sky-500 font-semibold"
                    : "text-primary-main"
                } 
                `}
            >
              <div className="py-1">
                {" "}
                <navItem.icon />{" "}
              </div>
              {!isCollapsed && (
                <div
                  className={` ${
                    selectedNavItemIndex === navIndex
                      ? "text-white"
                      : "text-primary-main"
                  }`}
                >
                  {" "}
                  {navItem.label}{" "}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default VerticalNavBar;
