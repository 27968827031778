import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL, API_HEADER } from "./URL";

export const fetchContacts = createAsyncThunk(
  "contacts/fetchContacts",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_URL}/contact`, API_HEADER);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteContact = createAsyncThunk(
  "contacts/deleteContact",
  async ({ contactId }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${API_URL}/contact/${contactId}`,
        API_HEADER
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const contactSlice = createSlice({
  name: "contact",

  initialState: {
    contacts: [],
    loading: false,
  },
  reducers: {},
  extraReducers: {
    [fetchContacts.pending]: (state) => {
      state.loading = true;
    },
    [fetchContacts.fulfilled]: (state, action) => {
      state.contacts = action.payload.contacts;
      state.loading = false;
    },
    [fetchContacts.rejected]: (state) => {
      state.loading = false;
    },
    [deleteContact.fulfilled]: (state, action) => {
      state.contacts = state.contacts.filter(
        (item) => item._id !== action.payload.deletedContactId
      );
      state.loading = false;
    },
  },
});
// export const {} = authSlice.actions;

export default contactSlice.reducer;
