import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL, API_HEADER } from "./URL";

export const fetchBusiness = createAsyncThunk(
  "business/fetchBusiness",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_URL}/business`, API_HEADER);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchBusinessOfMember = createAsyncThunk(
  "business/fetchBusinessOfMember",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${API_URL}/business/ofmember`,
        API_HEADER
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const changeStatusBusiness = createAsyncThunk(
  "business/status",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${API_URL}/business/${data.id}/status`,
        { status: data.value },
        API_HEADER
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteBusiness = createAsyncThunk(
  "business/deleteBusiness",
  async ({ businessId }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${API_URL}/business/${businessId}`,
        API_HEADER,
        businessId
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createBusiness = createAsyncThunk(
  "business/createBusiness",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${API_URL}/business`,
        data,
        API_HEADER
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const editBusiness = createAsyncThunk(
  "business/editBusiness",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${API_URL}/business/${data._id}`,
        data,
        API_HEADER
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const editBusinessByAdmin = createAsyncThunk(
  "business/editBusiness",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${API_URL}/business/${data._id}/admin`,
        data,
        API_HEADER
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchBusinessCategories = createAsyncThunk(
  "business/fetchBusinessCategories",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_URL}/category`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const createBusinessCategory = createAsyncThunk(
  "business/createBusinessCategory",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${API_URL}/category`,
        data,
        API_HEADER
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const editBusinessCategory = createAsyncThunk(
  "business/editBusinessCategory",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${API_URL}/category/${data._id}`,
        data,
        API_HEADER
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteBusinessCategory = createAsyncThunk(
  "business/deleteBusinessCategory",
  async ({ categoryId }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${API_URL}/category/${categoryId}`,
        API_HEADER,
        categoryId
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const businessSlice = createSlice({
  name: "business",

  initialState: {
    business: [],
    loading: false,
    categories: [],
  },
  reducers: {},
  extraReducers: {
    [fetchBusiness.pending]: (state) => {
      state.loading = true;
    },
    [fetchBusiness.fulfilled]: (state, action) => {
      state.business = action.payload.data;
      state.loading = false;
    },
    [fetchBusiness.rejected]: (state) => {
      state.loading = false;
    },
    [fetchBusinessOfMember.pending]: (state) => {
      state.loading = true;
    },
    [fetchBusinessOfMember.fulfilled]: (state, action) => {
      state.business = action.payload.data;
      state.loading = false;
    },
    [fetchBusinessOfMember.rejected]: (state) => {
      state.loading = false;
    },

    [deleteBusiness.fulfilled]: (state, action) => {
      state.business = state.business.filter(
        (item) => item._id !== action.payload.deletedBusinessId
      );
      state.loading = false;
    },
    [changeStatusBusiness.fulfilled]: (state, action) => {
      const updatedBusinesses = state.business.map((business) => {
        if (business._id === action.payload.data._id) {
          return action.payload.data;
        } else {
          return business;
        }
      });
      state.business = updatedBusinesses;
      state.loading = false;
    },
    [fetchBusinessCategories.fulfilled]: (state, action) => {
      state.categories = action.payload.categories;
    },
    [createBusinessCategory.fulfilled]: (state, action) => {
      state.categories.push(action.payload.category);
    },
    [deleteBusinessCategory.fulfilled]: (state, action) => {
      state.categories = state.categories.filter(
        (item) => item._id !== action.payload.categoryId
      );
      state.loading = false;
    },
  },
});
// export const {} = authSlice.actions;

export default businessSlice.reducer;
