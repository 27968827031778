import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Topbar from "./Topbar/Topbar";
import Footer1 from "./Footer/Footer1";
import VerticalNavBar from "../UI/Verticalnavbar/VerticalNavBar";
import { setIsCollapsed } from "../../redux/slices/Sidenavlayoutslice";
import { navigation, memberNavigation } from "../../navigation";

const Layout1 = ({ children }) => {
  const dispatch = useDispatch();
  const memberToken = localStorage.getItem("memberToken");

  const sideNavLayoutState = useSelector((state) => state.sideNavLayout);
  const { isCollapsed } = sideNavLayoutState;

  const toggleCollapse = () => {
    dispatch(setIsCollapsed(!isCollapsed));
  };

  const location = useLocation();
  const shouldRenderLayout = location.pathname !== "/login";
  const [isMobileView, setIsMobileView] = useState(false);

  const handleResize = () => {
    setIsMobileView(window.innerWidth < 768); 
  };

  useEffect(() => {
    handleResize(); 
    window.addEventListener("resize", handleResize); 

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isMobileView) {
      dispatch(setIsCollapsed(true));
    } else {
      dispatch(setIsCollapsed(false));
    }
  }, [isMobileView, dispatch]);

  if (!shouldRenderLayout) {
    return (
      <div className="flex overflow-hidden">
        <div className="h-full w-full bg-slate-50 px-4">
          {children}
        </div>
      </div>
    );
  }


  return (
    <div className="flex min-h-screen bg-gray-200 overflow-hidden ">
      <div
        className={`h-full border-l ${
          isCollapsed ? "min-w-full" : "min-w-full"
        }`}
      >
        <div className="container-fluid">
          <div className="row flex flex-row ">
            <div
              className={` col-md-4 border-slate-300 h-full transition-all duration-500 ease-in-out   ${
                isCollapsed ? "min-w-[50px] w-[50px]" : "min-w-[250px] w-[250px]"
              }`}
            >
              {shouldRenderLayout && (
                <VerticalNavBar
                  toggleCollapse={toggleCollapse}
                  isCollapsed={isCollapsed}
                  navigation={memberToken ? memberNavigation : navigation}
                  isPathEqualtoNavItem={(navItem) => false}
                />
              )}
            </div>
            <div className="col-md-8 grow w-full h-full overflow-auto ">
              <Topbar />
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout1;