import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL, API_HEADER } from "./URL";

export const fetchMBlogs = createAsyncThunk(
  "blogs/fetchMBlogs",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${API_URL}/mediablogs/for-admin`,
        API_HEADER
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const createMBlog = createAsyncThunk(
  "blogs/createMBlog",
  async ({ formDataForSubmission }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${API_URL}/mediablogs`,
        formDataForSubmission,
        API_HEADER
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const editMBlog = createAsyncThunk(
  "blogs/editMBlog",
  async ({ formDataForSubmission }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${API_URL}/mediablogs/${formDataForSubmission.get("_id")}`,
        formDataForSubmission,
        API_HEADER
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteMBlog = createAsyncThunk(
  "blogs/deleteMBlog",
  async ({ blogId }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${API_URL}/mediablogs/${blogId}`,
        API_HEADER,
        blogId
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const changeMBlogAccess = createAsyncThunk(
  "blogs/changeMBlogAccess",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${API_URL}/mediablogs/${data.id}/access`,
        data,
        API_HEADER
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const mediaBlogSlice = createSlice({
  name: "mblogs",

  initialState: {
    mblogs: [],
    loading: false,
  },
  reducers: {},
  extraReducers: {
    [fetchMBlogs.pending]: (state) => {
      state.loading = true;
    },
    [fetchMBlogs.fulfilled]: (state, action) => {
      state.mblogs = action.payload.data;
      state.loading = false;
    },
    [fetchMBlogs.rejected]: (state) => {
      state.loading = false;
    },
    [deleteMBlog.fulfilled]: (state, action) => {
      state.mblogs = state.mblogs.filter(
        (item) => item._id !== action.payload.deleteMblogId
      );
      state.loading = false;
    },
    [changeMBlogAccess.fulfilled]: (state, action) => {
      const updatedBlog = state.mblogs.map((mblog) => {
        if (mblog._id === action.payload.updatedBlogPost._id) {
          return action.payload.updatedBlogPost;
        } else {
          return mblog;
        }
      });
      state.mblogs = updatedBlog;
      state.loading = false;
    },
  },
});
// export const {} = authSlice.actions;

export default mediaBlogSlice.reducer;
