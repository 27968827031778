// export const API_URL = "http://localhost:8080/api";
// export const BASE_URL = "http://localhost:8080";
// export const USER_URL = "http://localhost:3000";

export const API_URL = "https://api.canadiancleaningassociation.com/api";
export const BASE_URL = "https://api.canadiancleaningassociation.com";
export const USER_URL = "https://user.canadiancleaningassociation.com";

export const WEB_URL = "https://www.canadiancleaningassociation.com";

export const API_HEADER = {
  headers: {
    Authorization: localStorage.getItem("adminToken")
      ? localStorage.getItem("adminToken")
      : localStorage.getItem("memberToken"),
  },
};
