import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useLoginMutation } from "../services/authService";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom/dist";
import loginimg from "../assets/image/login.jpg";
import axios from "axios";
import { API_URL, USER_LOGIN_BASE_URL } from "../redux/slices/URL";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import Topbar from "../components/Layout/Topbar/Topbar";
import Footer1 from "../components/Layout/Footer/Footer1";
import { BASE_URL } from "../utils/constants";
import Cookies from "js-cookie";

const ClientLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  //   const [error, setError] = useState("");
  //   const [apiError, setApiError] = useState("");

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [visible, setVisible] = useState(false);
  const [login] = useLoginMutation();

  // useEffect(() => {
  //   if (user) {
  //     navigate("/dashboard");
  //   }
  // }, [user]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setEmailError("");
    setPasswordError("");

    if (!data.email) {
      setEmailError("Email is required");
      setVisible(true);
      return;
    }
    if (!data.password) {
      setPasswordError("Password is required");
      setVisible(true);
      return;
    }
    setLoading(true);

    try {
      const response = await axios.post(`${API_URL}/members/login`, {
        email: data?.email,
        password: data?.password,
      });
      if (response?.data?.success) {
        localStorage.setItem("memberToken", response?.data?.token);
        // Common options for cookies
        const cookieOptions = {
          sameSite: "None",
          secure: true,
          path: "/",
        };
        const cookieNames = ["memberToken", "name", "email", "userName"];
        const setCookies = (domain) => {
          cookieNames.forEach((cookieName) => {
            if (cookieName === "memberToken") {
              Cookies.set(cookieName, response?.data?.["token"], {
                ...cookieOptions,
                domain: domain,
              });
            } else {
              Cookies.set(cookieName, response?.data?.[cookieName], {
                ...cookieOptions,
                domain: domain,
              });
            }
          });
        };
        setCookies("canadiancleaningassociation.com");
        setCookies("localhost");
        setCookies("user.canadiancleaningassociation.com");
        setCookies("user.canadiancleaningassociation.ca");
        setCookies("canadiancleaningassociation.ca");

        toast.success("Member Login Sucessfully", {
          position: "top-right",
        });
        // navigate("/dashboard");
        window.location.replace("/");
        setLoading(false);
      } else {
        toast.info(response?.data?.message);
        setLoading(false);
      }
    } catch (err) {
      // console.log(err);
      toast.error(err?.response?.data?.message);
      setLoading(false);
    }
  };

  return (
    <>
      <Topbar />
      <div className="min-h-screen flex flex-col justify-center items-center md:flex-row bg-gray-100">
        <div className="flex flex-col w-5/6 h-1/2 md:w-2/5 md:h-1/2 lg:w-1/3 lg:h-1/2 pt-5 pb-5 rounded-lg shadow-2xl bg-white justify-center items-center">
          <div className="text-lg md:text-xl lg:text-2xl text-center text-cyan-900">
            <strong>
              <FontAwesomeIcon icon={faUsers} className="mr-2 " />
              Members
            </strong>{" "}
            Login
            <p className="text-base px-2 lg:text-base text-cyan-900 sm:px-20 mb-4 mt-4">
              Please Enter Your Email and Password below to login
            </p>
          </div>
          <form class="space-y-6" onSubmit={handleLogin}>
            <div>
              <label
                for="email"
                class="block text-sm font-medium leading-6 mb-2 text-gray-900"
              >
                Email address
              </label>
              <div className="md:w-[350px]">
                <input
                  id="email"
                  type="email"
                  required
                  className="block w-full  
                  rounded-md border-0 px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
                 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Email"
                  value={data.email}
                  onChange={(event) =>
                    setData({ ...data, email: event.target.value })
                  }
                />
              </div>
            </div>

            <div>
              <div class="flex items-center justify-between">
                <label
                  for="password"
                  class="block text-sm font-medium leading-6 mb-2 text-gray-900"
                >
                  Password
                </label>
              </div>
              <div class="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Password"
                  value={data.password}
                  onChange={(event) =>
                    setData({ ...data, password: event.target.value })
                  }
                  required
                  className=" px-4 py-3 block w-full rounded-md border-0 
                  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                disabled={loading}
                class="flex w-full justify-center rounded-md bg-cyan-900 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {" "}
                {loading ? "Loading..." : "Login"}
              </button>
            </div>
            <div class="flex items-center justify-between">
              <div class="flex items-start">
                <div class="flex items-center h-5">
                  <input
                    type="checkbox"
                    class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                    required=""
                  />
                </div>
                <div class="ml-3 text-sm">
                  <label
                    for="remember"
                    class="text-gray-500 dark:text-gray-300"
                  >
                    Remember me
                  </label>
                </div>
              </div>
              {/* <a
                href="#"
                class="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
              >
                Forgot password?
              </a> */}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ClientLogin;
