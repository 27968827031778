import logo from "./logo.svg";
import "./App.css";
import { Provider, useDispatch } from "react-redux";
import Routing from "./Routing";
import { Toaster } from "react-hot-toast";
import { setAccessToken, setUser } from "./redux/slices/AuthSlice";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function App() {
  const dispatch = useDispatch();

  // useEffect(() => {
  //   const userDataString = localStorage.getItem("userData");
  //   if (userDataString) {
  //     const userData = JSON.parse(userDataString);
  //     dispatch(setUser(userData));
  //     dispatch(setAccessToken(userData.accessToken))
  //   }
  // }, [dispatch]);
  useEffect(() => {
    const adminToken = localStorage.getItem("adminToken");
    if (adminToken) {
      dispatch(setAccessToken(adminToken));
    }
  }, []);

  return (
    <>
      <Routing />
      <Toaster />
    </>
  );
}

export default App;
