import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  editBusiness,
  fetchBusinessCategories,
} from "../../../redux/slices/businessSlice";
import { useNavigate, useLocation } from "react-router-dom";
import { STATES } from "../../../utils/constants";
import { API_HEADER, API_URL, BASE_URL } from "../../../redux/slices/URL";
import axios from "axios";

import { CountryDropdown, RegionDropdown } from "react-country-region-selector";

const EditMemBusiness = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { row } = location.state;
  const [countryid, setCountryid] = useState(0);
  const [stateid, setStateid] = useState(0);
  const { categories } = useSelector((state) => state.business);

  const [formData, setFormData] = useState({
    _id: row._id,
    name: row.name,
    email: row.email,
    description: row.description,
    category: row.category,
    logo: row.logo,
    address: row.address,
    state: row.state,
    city: row.city,
    zipCode: row.zipCode,
    country: row.country,
    phone: row.phone,
    website: row.website,
    fax: row.fax,
  });
  console.log(formData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isAnyFieldEmpty = Object.keys(formData).some((key) => {
      // Exclude 'logo' field from the check
      if (key !== "logo") {
        return formData[key] === "";
      }
      return false;
    });

    if (isAnyFieldEmpty) {
      toast.error("Please fill out all fields.");
    } else {
      // Regular expressions for validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const zipCodeRegex =
        /^(?:\d{5}(?:-\d{4})?|\b[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d)$/;

      const phoneRegex =
        /^(?:\+1)?(?:\d{10}|\d{3}-\d{3}-\d{4}|\(\d{3}\)\s*\d{3}-\d{4}|\d{3}\.\d{3}\.\d{4})$/;
      const websiteRegex =
        /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
      // const faxRegex = /^\d{12}$/;

      // Validation checks
      if (!emailRegex.test(formData.email)) {
        toast.error("Please enter a valid email address.");
      } else if (!zipCodeRegex.test(formData.zipCode)) {
        toast.error("Please enter a valid ZIP code.");
      } else if (!phoneRegex.test(formData.phone)) {
        toast.error("Please enter a valid phone number.");
      } else if (!websiteRegex.test(formData.website)) {
        toast.error("Please enter a valid website URL.");
      }
      // else if (formData.fax && !faxRegex.test(formData.fax)) {
      //   toast.error("Please enter a 12 digit fax number.");
      // }
      else {
        let data = { ...formData };
        dispatch(editBusiness({ data }))
          .then((res) => {
            if (res.payload.success) {
              toast.success("Edited");
            }
          })
          .catch((error) => toast.error(error.message));
        navigate("/user/business");
      }
    }
  };

  const handleImageUpload = async (e) => {
    try {
      const formData = new FormData();
      formData.append("logo", e?.target?.files[0]);
      const imageUplaodResponse = await axios.post(
        `${API_URL}/business/logoUpload`,
        formData,
        API_HEADER
      );
      if (imageUplaodResponse.data.success) {
        const { name } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: imageUplaodResponse?.data?.filePath,
        }));
        toast.success("logo uploaded");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const handleCountryChange = (val) => {
    setFormData((prevData) => ({
      ...prevData,
      country: val,
    }));
  };

  const handleStateChange = (val) => {
    setFormData((prevData) => ({ ...prevData, state: val }));
  };

  const handleCityChange = (val) => {
    setFormData((prevData) => ({ ...prevData, city: val }));
  };

  useEffect(() => {
    dispatch(fetchBusinessCategories());
  }, []);
  return (
    <>
      <div className=" mx-auto bg-white rounded p-8 shadow-md">
        <h2 className="text-2xl p-2 font-semibold bg-cyan-900 text-white mb-4 rounded-sm">
          Edit Business Information
        </h2>

        <form onSubmit={handleSubmit} className="flex flex-wrap -mx-2">
          <div className="mb-4 w-full md:w-1/2 px-2">
            <label
              htmlFor="name"
              className="block text-gray-600 text-sm font-medium mb-2"
            >
              Company Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className="w-full border rounded-md py-2 px-3 focus:outline-none focus:ring focus:border-blue-300"
            />
          </div>
          <div className="mb-4 w-full md:w-1/2 px-2">
            <label
              htmlFor="address"
              className="block text-gray-600 text-sm font-medium mb-2"
            >
              Address
            </label>
            <input
              type="text"
              id="address"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
              className="w-full border rounded-md py-2 px-3 focus:outline-none focus:ring focus:border-blue-300"
            />
          </div>
          <div className="mb-4 w-full md:w-1/2 px-2">
            <label
              htmlFor="country"
              className="block text-gray-600 text-sm font-medium mb-2"
            >
              Country
            </label>
            <CountryDropdown
              id="country"
              name="country"
              value={formData.country}
              onChange={(val) => handleCountryChange(val)}
              className="w-full border border-gray-300 rounded-md p-2"
              whitelist={["CA", "US"]}
            />
          </div>
          <div className="mb-4 w-full md:w-1/2 px-2">
            <label
              htmlFor="state"
              className="block text-gray-600 text-sm font-medium mb-2"
            >
              State
            </label>
            <RegionDropdown
              id="state"
              name="state"
              country={formData.country}
              value={formData.state}
              onChange={(val) => handleStateChange(val)}
              className="w-full border border-gray-300 rounded-md p-2"
              disableWhenEmpty
            />
          </div>

          <div className="mb-4 w-full md:w-1/2 px-2">
            <label
              htmlFor="city"
              className="block text-gray-600 text-sm font-medium mb-2"
            >
              City
            </label>
            <input
              type="text"
              id="city"
              name="city"
              value={formData.city}
              onChange={handleInputChange}
              className="w-full border rounded-md py-2 px-3 focus:outline-none focus:ring focus:border-blue-300"
            />
          </div>
          <div className="mb-4 w-full md:w-1/2 px-2">
            <label
              htmlFor="zipCode"
              className="block text-gray-600 text-sm font-medium mb-2"
            >
              Postal Code
            </label>
            <input
              type="text"
              id="zipCode"
              name="zipCode"
              maxLength={10}
              value={formData.zipCode}
              onChange={handleInputChange}
              className="w-full border rounded-md py-2 px-3 focus:outline-none focus:ring focus:border-blue-300"
            />
          </div>

          <div className="mb-4 w-full md:w-1/2 px-2">
            <label
              htmlFor="phone"
              className="block text-gray-600 text-sm font-medium mb-2"
            >
              Phone
            </label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              maxLength={12}
              onKeyDown={(e) => {
                // Allow numeric characters, +, -, backspace, delete, arrow keys, and tab
                if (
                  !/^[\d+-]$/.test(e.key) &&
                  e.key !== "Backspace" &&
                  e.key !== "Delete" &&
                  e.key !== "ArrowLeft" &&
                  e.key !== "ArrowRight" &&
                  e.key !== "Tab"
                ) {
                  e.preventDefault();
                }
              }}
              className="w-full border rounded-md py-2 px-3 focus:outline-none focus:ring focus:border-blue-300"
            />
          </div>

          <div className="mb-4 w-full md:w-1/2 px-2">
            <label
              htmlFor="email"
              className="block text-gray-600 text-sm font-medium mb-2"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className="w-full border rounded-md py-2 px-3 focus:outline-none focus:ring focus:border-blue-300"
            />
          </div>
          <div className="mb-4 w-full md:w-1/2 px-2">
            <label
              htmlFor="fax"
              className="block text-gray-600 text-sm font-medium mb-2"
            >
              Fax
            </label>
            <input
              type="tel"
              id="fax"
              name="fax"
              maxLength={12}
              value={formData.fax}
              onChange={handleInputChange}
              onKeyDown={(e) => {
                // Allow numeric characters, +, -, backspace, delete, arrow keys, and tab
                if (
                  !/^[\d+-]$/.test(e.key) &&
                  e.key !== "Backspace" &&
                  e.key !== "Delete" &&
                  e.key !== "ArrowLeft" &&
                  e.key !== "ArrowRight" &&
                  e.key !== "Tab"
                ) {
                  e.preventDefault();
                }
              }}
              className="w-full border rounded-md py-2 px-3 focus:outline-none focus:ring focus:border-blue-300"
            />
          </div>
          <div className="mb-4 w-full md:w-1/2 px-2">
            <label
              htmlFor="website"
              className="block text-gray-600 text-sm font-medium mb-2"
            >
              Website
            </label>
            <input
              type="url"
              id="website"
              name="website"
              value={formData.website}
              onChange={handleInputChange}
              className="w-full border rounded-md py-2 px-3 focus:outline-none focus:ring focus:border-blue-300"
            />
          </div>

          {/* <div className="mb-4 w-full md:w-1/2 px-2"></div> */}

          <div className="mb-4 w-full md:w-1/2 px-2">
            <label
              htmlFor="category"
              className="block text-gray-600 text-sm font-medium mb-2"
            >
              Category
            </label>
            <select
              id="category"
              name="category"
              value={formData.category}
              onChange={handleInputChange}
              className="w-full border rounded-md py-2 px-3 focus:outline-none focus:ring focus:border-blue-300"
            >
              {categories &&
                categories.map((category, index) => (
                  <option key={index} value={category.name}>
                    {category.name}
                  </option>
                ))}
            </select>
            <span>
              <label
                htmlFor="description"
                className="block text-gray-600 text-sm font-medium mb-2"
              >
                Company Description
              </label>
              <textarea
                id="description"
                name="description"
                rows="3"
                value={formData.description}
                onChange={handleInputChange}
                className="w-full border rounded-md py-2 px-3 focus:outline-none focus:ring focus:border-blue-300"
              ></textarea>
            </span>
          </div>
          {/* ------------------ */}
          <div className="mb-2  w-full md:w-1/2 px-2">
            <label
              htmlFor="logo"
              className="block text-gray-600 text-sm font-medium mb-2"
            >
              Logo
            </label>
            <input
              type="file"
              id="logo"
              name="logo"
              accept="image/*"
              onChange={handleImageUpload}
              className="hidden"
            />
            <label htmlFor="logo" className="cursor-pointer">
              <div className="w-full h-40 border rounded-md flex items-center justify-center bg-gray-100">
                {formData.logo ? (
                  <img
                    src={`${formData.logo}`}
                    alt="Logo Preview"
                    className="object-contain w-full h-full rounded-md"
                  />
                ) : (
                  <span className="text-gray-500">Upload Logo</span>
                )}
              </div>
            </label>
          </div>
          {/* ------------------ */}

          <div className="w-full px-2 mt-6">
            <button
              type="submit"
              className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditMemBusiness;
