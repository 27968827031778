import React, { useEffect } from "react";
// import UserLkisting from "./UserLkisting";
import ShowDialogBox from "../../components/ShowDialogBox/ShowDialogBox";
import { useDispatch, useSelector } from "react-redux";

import BcategoriesListing from "./BcategoriesListing";
import {
  deleteBusinessCategory,
  fetchBusinessCategories,
} from "../../redux/slices/businessSlice";
import { FaPencil } from "react-icons/fa6";
import { FaTrashAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const BcategoriesListingWrapper = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { categories, loading } = useSelector((state) => state.business);

  useEffect(() => {
    dispatch(fetchBusinessCategories());
  }, []);

  const columns = [
    {
      field: "name",
      headerName: "Category Name",
      flex: "flex-[1_1_0%]",
      renderCell: (row) => <span> {`${row.name}`} </span>,
    },

    {
      field: "createdat",
      headerName: "Created At",
      flex: "flex-[1_1_0%]",
      renderCell: (row) => {
        return <span> {`${new Date(row.createdAt).toLocaleDateString()}`} </span>;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: "flex-[0.5_0.5_0%]",
      renderCell: (row) => (
        <>
          <FaPencil
            onClick={() => handleEdit(row)}
            style={{ cursor: "pointer", marginRight: "8px" }}
          />
          <FaTrashAlt
            onClick={() => handleDelete(row)}
            style={{ cursor: "pointer", marginRight: "8px" }}
          />
        </>
      ),
      align: "end",
    },
  ];
  const handlePackageDelete = (categoryId) => {
    dispatch(deleteBusinessCategory({ categoryId }));
  };
  const handleEditCategoryName = (categoryId) => {};

  const handleDelete = (row) => {
    ShowDialogBox({
      title: "Delete Item",
      text: "Are you sure you want to delete this item?",
      icon: "warning",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel", // Provide cancel button text
      successTitle: "Deleted!",
      successText: "The item has been deleted successfully.",
      itemId: row._id,
      deleteItem: handlePackageDelete,
    });
  };
  const handleEdit = (row) => {
    navigate("edit", {
      state: {
        row: row,
      },
    });
  };

  return (
    <>
      <BcategoriesListing
        rows={categories}
        columns={columns}
        loading={loading}
      />
    </>
  );
};

export default BcategoriesListingWrapper;
