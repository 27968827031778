import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faLinkedin,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { BiUser, BiEnvelope, BiLock, BiLockAlt } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { API_HEADER, API_URL } from "../../redux/slices/URL";
import toast from "react-hot-toast";
import Loader from "../../components/Loader/Loader";

const Setting = () => {
  const dispatch = useDispatch();
  const { admin, user, loading } = useSelector((state) => state.auth);
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (admin) {
      try {
        const response = await axios.patch(
          `${API_URL}/admin/changePassword`,
          { email: admin.email, oldPassword: oldPass, newPassword: newPass },
          API_HEADER
        );
        if (response?.data?.success) {
          toast.success(response?.data?.message);
          setOldPass("");
          setNewPass("");
        }
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.message);
      }
    } else {
      try {
        const response = await axios.patch(
          `${API_URL}/members/changePassword`,
          { oldPassword: oldPass, newPassword: newPass },
          API_HEADER
        );
        if (response?.data?.success) {
          toast.success(response?.data?.message);
          setOldPass("");
          setNewPass("");
        }
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.message);
      }
    }
  };

  return (
    <div className={`p-4 `}>
      <div className="bg-white p-8 rounded shadow-sm flex flex-col">
        <div className="border rounded-lg">
          <h2 className="text-sm md:text-base lg:text-xl bg-gray-300 p-2 mb-4">
            Profile Setting
          </h2>
          <div className="flex flex-col justify-center container mx-auto pt-4 pl-4">
            <div className="mb-3 row  flex flex-wrap items-center">
              <span className="input-group-text bg-cyan-900 text-light">
                <BiLock />
              </span>
              <label
                htmlFor="oldPassword"
                className="col-sm-2 col-form-label text-xs sm:text-base md:text-base lg:text-base"
              >
                Old Password :
              </label>
              <div className="col-sm-10">
                <div className="input-group text-xs sm:text-base md:text-lg lg:text-xl">
                  <input
                    type="password"
                    className=" form-control px-1 w-full md:w-60  rounded pass-inputs text-xs sm:text-sm md:text-sm lg:text-sm "
                    id="oldPassword"
                    placeholder="Enter old password"
                    required
                    onChange={(e) => {
                      setOldPass(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="mb-3 row  flex flex-wrap items-center">
              <span className="input-group-text bg-cyan-900 text-light">
                <BiLockAlt />
              </span>
              <label
                htmlFor="newPassword"
                className="col-sm-2 col-form-label text-xs sm:text-base md:text-base lg:text-base"
              >
                New Password :
              </label>
              <div className="col-sm-10">
                <div className="input-group text-xs sm:text-base md:text-lg lg:text-xl">
                  <input
                    type="password"
                    className="form-control px-1 w-full md:w-60  rounded pass-inputs text-xs sm:text-sm md:text-sm lg:text-sm "
                    id="newPassword"
                    placeholder="Enter new password"
                    required
                    onChange={(e) => {
                      setNewPass(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="mb-3 row  flex flex-wrap items-center">
              <span className="input-group-text bg-cyan-900 text-light">
                <BiLockAlt />
              </span>
              <label
                htmlFor="newPassword"
                className="col-sm-2 col-form-label text-xs sm:text-base md:text-base lg:text-base"
              >
                Confirm Password :
              </label>
              <div className="col-sm-10">
                <div className="input-group">
                  <input
                    type="password"
                    className="form-control px-1 w-full md:w-60  rounded pass-inputs text-xs sm:text-sm md:text-sm lg:text-sm"
                    id="newPassword"
                    placeholder="Re-enter password"
                    required
                    onChange={(e) => {
                      setNewPass(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="text-center">
              <button
                type="submit"
                className=" bg-cyan-900 text-xs md:text-base lg:text-base rounded-xl text-white p-2 m-2 md:p-2 lg:p-2"
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Setting;
