import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL, API_HEADER } from "./URL";

export const fetchEvents = createAsyncThunk(
  "events/fetchEvents",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${API_URL}/event/for-admin`,
        API_HEADER
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchUserEvents = createAsyncThunk(
  "events/fetchUserEvents",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${API_URL}/event/user-event`,
        API_HEADER
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const createEvent = createAsyncThunk(
  "events/createEvent",
  async ({ formData }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${API_URL}/event`,
        formData,
        API_HEADER
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const editEvent = createAsyncThunk(
  "events/editEvent",
  async ({ formData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${API_URL}/event/${formData._id}`,
        formData,
        API_HEADER
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteEvent = createAsyncThunk(
  "events/deleteEvent",
  async ({ eventId }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${API_URL}/event/${eventId}`,
        API_HEADER,
        eventId
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const eventSlice = createSlice({
  name: "events",

  initialState: {
    events: [],
    loading: false,
  },
  reducers: {},
  extraReducers: {
    [fetchEvents.pending]: (state) => {
      state.loading = true;
    },
    [fetchEvents.fulfilled]: (state, action) => {
      state.events = action.payload.data;
      state.loading = false;
    },
    [fetchEvents.rejected]: (state) => {
      state.loading = false;
    },
    [fetchUserEvents.pending]: (state) => {
      state.loading = true;
    },
    [fetchUserEvents.fulfilled]: (state, action) => {
      state.events = action.payload.data;
      state.loading = false;
    },
    [fetchUserEvents.rejected]: (state) => {
      state.loading = false;
    },
    [deleteEvent.fulfilled]: (state, action) => {
      state.events = state.events.filter(
        (item) => item._id !== action.payload.deletedEventId
      );
      state.loading = false;
    },
  },
});
// export const {} = authSlice.actions;

export default eventSlice.reducer;
