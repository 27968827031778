import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { editBlog, fetchBlogsCategories } from "../../redux/slices/BlogSlice";
import toast from "react-hot-toast";
import ReactQuill from "react-quill";
import "./css/Addblog.css";
import { API_HEADER, API_URL } from "../../redux/slices/URL";
import axios from "axios";

const EditBlog = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { row } = location.state;
  const { categories } = useSelector((state) => state.blogs);
  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Format month and day to have leading zeros if necessary
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }

    return `${year}-${month}-${day}`;
  };

  const [formData, setFormData] = useState({
    _id: row._id,
    title: row.title,
    content: row.content,
    image: row.image,
    category: row.category,
    tags: row.tags,
    publishDate: row.publishDate,
  });
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    // "image",
  ];
  const handleTextChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      content: value,
    }));
  };

  const handleChange = (e) => {
    const { name, value, type } = e.target;

    if (type === "file") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: e.target.files[0],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const handleImageUpload = async (e) => {
    try {
      const formData = new FormData();
      formData.append("image", e?.target?.files[0]);
      const imageUplaodResponse = await axios.post(
        `${API_URL}/blogs/imageUpload`,
        formData,
        API_HEADER
      );
      if (imageUplaodResponse.data.success) {
        const { name } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: imageUplaodResponse?.data?.filePath,
        }));
        toast.success("File uploaded");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      const { title, content } = formData;
      if (title && content) {
        const formDataForSubmission = new FormData();
        formDataForSubmission.append("_id", formData._id);
        formDataForSubmission.append("title", formData.title);
        formDataForSubmission.append("content", formData.content);
        formDataForSubmission.append("image", formData?.image);
        formData.tags.map((tag) => {
          return formDataForSubmission.append("tags", tag);
        });
        formDataForSubmission.append("category", formData?.category);
        formDataForSubmission.append("publishDate", formData?.publishDate);
        dispatch(editBlog({ formDataForSubmission }))
          .then((res) => {
            if (res.payload.success) {
              toast.success("Updated");
            }
          })
          .catch((error) => toast.error(error.message));
        navigate("/blogs");
      } else {
        toast.error("Title and Content are Required");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key !== "Enter") return;
    const value = e.target.value;
    if (!value.trim()) return;

    setFormData((prevFormData) => ({
      ...prevFormData,
      tags: [...prevFormData.tags, value],
    }));
    e.target.value = "";
  };

  const removeTag = (index) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      tags: prevFormData.tags.filter((_, i) => i !== index),
    }));
  };
  useEffect(() => {
    dispatch(fetchBlogsCategories());
  }, []);

  return (
    <>
      <div class=" mx-auto bg-white p-8 rounded-md shadow-md">
        <h2 class="text-2xl font-semibold mb-4">Edit Blog</h2>
        {/* <form onSubmit={handleSubmit}> */}
        <div class="mb-4">
          <label for="title" class="block text-sm font-medium text-gray-600">
            Title
          </label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            class="mt-1 p-2 w-full border rounded-md"
            onChange={handleChange}
          />
        </div>

        <div class="mb-4">
          <label for="content" class="block text-sm font-medium text-gray-600">
            Content
          </label>
          <ReactQuill
            value={formData.content}
            modules={modules}
            formats={formats}
            onChange={(e) => handleTextChange(e)}
          />
          {/* <textarea
              id="content"
              name="content"
              rows="4"
              value={formData.content}
              class="mt-1 p-2 w-full border rounded-md"
              onChange={handleChange}
            ></textarea> */}
        </div>
        <div class="mb-4 ">
          <label for="category" class="block text-sm font-medium text-gray-600">
            Category
          </label>
          <select
            className="w-full border rounded-md py-2 px-3 focus:outline-none focus:ring focus:border-blue-300"
            name="category"
            id="category"
            value={formData.category}
            onChange={(e) => handleChange(e)}
          >
            {formData.category ? null : (
              <option value="">select category</option>
            )}
            {categories?.map((item, index) => (
              <option key={index} value={item.name}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
        {/* === */}
        <div className="tags-input-container">
          <label for="category" class="block text-sm font-medium text-gray-600">
            Tags
          </label>
          {formData.tags.map((tag, index) => (
            <div className="tag-item" key={index}>
              <span className="text">{tag}</span>
              <span className="close" onClick={() => removeTag(index)}>
                &times;
              </span>
            </div>
          ))}
          <input
            onKeyDown={handleKeyDown}
            type="text"
            className="tags-input"
            placeholder="Enter tag"
          />
        </div>
        {/* === */}

        <div class="mb-4 ">
          <label
            for="Publish Date"
            class="block text-sm font-medium text-gray-600"
          >
            Publish on
          </label>
          <input
            type="date"
            id="publishDate"
            name="publishDate"
            value={formData.publishDate}
            class="mt-1 p-2 w-full border rounded-md"
            onChange={handleChange}
            min={getTodayDate()}
          />
        </div>

        {/* ------------------ */}
        <div className="mb-2  w-full md:w-1/2 px-2">
          <label
            htmlFor="image"
            className="block text-gray-600 text-sm font-medium mb-2"
          >
            Image
          </label>
          <input
            type="file"
            id="image"
            name="image"
            accept="image/*"
            onChange={handleImageUpload}
            className="hidden"
          />
          <label htmlFor="image" className="cursor-pointer">
            <div className="w-full h-40 border rounded-md flex items-center justify-center bg-gray-100">
              {formData.image ? (
                <img
                  src={`${formData.image}`}
                  alt="Preview"
                  className="object-contain w-full h-full rounded-md"
                />
              ) : (
                <span className="text-gray-500">Upload Image</span>
              )}
            </div>
          </label>
        </div>
        {/* ------------------ */}

        <div>
          <button
            // type="submit"
            onClick={(e) => handleSubmit(e)}
            class="bg-green-500 text-white py-2 px-4 rounded-md"
          >
            Submit
          </button>
        </div>
        {/* </form> */}
      </div>
    </>
  );
};

export default EditBlog;
