import axios from "axios";
import { useEffect } from "react";
import toast from "react-hot-toast";
import Loader from "../../components/Loader/Loader";
import { API_URL, API_HEADER, USER_URL } from "../../redux/slices/URL";
const Success = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const paymentIntentStatus = urlSearchParams.get("redirect_status");
  const paymentToken = urlSearchParams.get("paymentoken");
  const handleStripePayment = async () => {
    try {
      const response = await axios.patch(
        `${API_URL}/members/renew`,
        {
          paymentToken: paymentToken,
        },
        API_HEADER
      );
      if (response.data.success) {
        toast.success("Renewed");
        setTimeout(() => {
          window.location.href = USER_URL;
        }, 2000);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  useEffect(() => {
    try {
      if (paymentIntentStatus === "succeeded") {
        toast.success("Payment Success");
        if (paymentToken) {
          handleStripePayment();
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  }, []);

  return (
    <div className="flex flex-row mx-auto justify-center my-[10rem]">
      <Loader />
    </div>
  );
};

export default Success;
