import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TableHeader from "../../components/Table/TableHeader";
import Table from "../../components/Table/Table";

const EventListing = ({ rows, columns, loading }) => {
  const navigate = useNavigate();

  const [selectedRows, setSelectedRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState(rows);
  const [searchQuery, setSearchQuery] = useState("");
  useEffect(() => {
    // Filter rows based on search query and parameter filter
    let filteredData = rows;
    if (searchQuery !== "") {
      filteredData = filteredData.filter(
        (row) =>
          row.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          row.location.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    setFilteredRows(filteredData);
  }, [rows, searchQuery]);

  return (
    <div className="px-4 h-[calc(100vh-55px)] pt-3">
      {/* Page Header */}
      <div className="flex justify-between items-center pt-5 pb-5">
        <span className="text-xl font-semibold text-primary-main">Events</span>
        <div className="flex gap-3">
          <button
            onClick={() => navigate("add")}
            className="bg-primary-main text-white text-sm rounded py-2 px-3"
          >
            Add Event
          </button>
        </div>
      </div>
      <div className="border flex flex-col h-[calc(100%-55px)] rounded bg-white">
        <div className="flex justify-between m-2">
          <form>
            <label
              htmlFor="default-search"
              className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
            >
              Search
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="search"
                id="default-search"
                className="block w-full py-3 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Search ..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                required
              />
            </div>
          </form>
        </div>{" "}
        <div className="grow overflow-auto">
          <Table
            rows={filteredRows}
            columns={columns}
            isCheckbox={true}
            selectedRows={selectedRows}
            onRowSelect={(selectedRows) => setSelectedRows(selectedRows)}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default EventListing;
