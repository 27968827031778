import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
// import CheckoutForm from "./Calander/Checkout";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import Loader from "../../components/Loader/Loader";
import { BASE_URL } from "../../redux/slices/URL";
import CheckoutForm from "./Checkout";

const stripePromise = loadStripe(
  "pk_live_51Oe44NDFsvkqauFeT1yWonAV0BzvD2PAFrtr8eSMyFZa59jzrvvUUnlRrImEQsojNFL3SCfst9h5MjB9Y6BBFido00HaSSOzWR"
);
// const stripePromise = loadStripe(
//   "pk_test_51Oe44NDFsvkqauFed3YL2S4b2KfAzJ2eFRDpHgWf8cqG881pjeuiYv46rM3bRrfJbo3DwBcCJfqE8C36RAfxP1uL00n0i5EREw"
// );

function Payment() {
  const location = useLocation();
  const [clientSecret, setClientSecret] = useState("");
  const { amount } = location.state;

  const { renewToken } = useParams();

  const createPaymentIntentOnLoad = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/create-payment-intent`, {
        amount: amount,
      });
      if (response.data) {
        console.log(response.data.clientSecret);
        setClientSecret(response.data.clientSecret);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };
  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  useEffect(() => {
    if (amount && amount !== undefined && amount !== null) {
      createPaymentIntentOnLoad();
    }
  }, [amount]);

  return (
    <>
      <div className="w-3/4 md:w-2/3  mx-auto p-4 border my-6">
        <div className="col-md-7 order-md-1 p-4 border-slate-500 rounded-lg shadow-xl">
          {clientSecret ? (
            <Elements stripe={stripePromise} options={options}>
              <CheckoutForm amount={amount} paymentoken={renewToken} />
            </Elements>
          ) : (
            <div className="flex justify-center">
              <Loader />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Payment;
