import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL, API_HEADER } from "./URL";

export const fetchMembers = createAsyncThunk(
  "member/fetchMembers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_URL}/members`, API_HEADER);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteMember = createAsyncThunk(
  "member/deleteMember",
  async ({ memberId }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${API_URL}/members/${memberId}`,
        API_HEADER,
        memberId
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const changeUserStatus = createAsyncThunk(
  "member/status",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${API_URL}/members/changeStatus`,
        data,
        API_HEADER
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const memberSlice = createSlice({
  name: "member",

  initialState: {
    members: [],
    loading: false,
  },
  reducers: {},
  extraReducers: {
    [fetchMembers.pending]: (state) => {
      state.loading = true;
    },
    [fetchMembers.fulfilled]: (state, action) => {
      state.members = action.payload;
      state.loading = false;
    },
    [fetchMembers.rejected]: (state) => {
      state.loading = false;
    },

    [deleteMember.fulfilled]: (state, action) => {
      state.members = state.members.filter(
        (member) => member._id !== action.payload.deletedMemberId
      );
      state.loading = false;
    },
    [changeUserStatus.fulfilled]: (state, action) => {
      const updatedMembers = state.members.map((member) => {
        if (member._id === action.payload.data._id) {
          return action.payload.data;
        } else {
          return member;
        }
      });
      state.members = updatedMembers;
      state.loading = false;
    },
  },
});
// export const {} = authSlice.actions;

export default memberSlice.reducer;
