import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL, API_HEADER } from "./URL";

export const fetchDashboard = createAsyncThunk(
  "package/fetchDashboard",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_URL}/dashboard`, API_HEADER);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchUserDashboard = createAsyncThunk(
  "package/fetchUserDashboard",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_URL}/dashboard/user`, API_HEADER);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const dashboardSlice = createSlice({
  name: "dashboard",

  initialState: {
    dashboard: {},
    loading: false,
  },
  reducers: {},
  extraReducers: {
    [fetchDashboard.pending]: (state) => {
      state.loading = true;
    },
    [fetchDashboard.fulfilled]: (state, action) => {
      state.dashboard = action.payload;
      state.loading = false;
    },
    [fetchDashboard.rejected]: (state) => {
      state.loading = false;
    },
    [fetchUserDashboard.pending]: (state) => {
      state.loading = true;
    },
    [fetchUserDashboard.fulfilled]: (state, action) => {
      state.dashboard = action.payload;
      state.loading = false;
    },
    [fetchUserDashboard.rejected]: (state) => {
      state.loading = false;
    },
  },
});
// export const {} = authSlice.actions;

export default dashboardSlice.reducer;
