import React, { useEffect, useState } from "react";
// import UserLkisting from "./UserLkisting";
import { useNavigate } from "react-router-dom";
import { FaPencil } from "react-icons/fa6";
import { FaTrashAlt } from "react-icons/fa";
import ShowDialogBox from "../../../components/ShowDialogBox/ShowDialogBox";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteEvent,
  fetchEvents,
  fetchUserEvents,
} from "../../../redux/slices/EventSlice";
import MemberEventListing from "./memEventListing";

const MemberEventListingWrapper = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { events, loading } = useSelector((state) => state.event);

  useEffect(() => {
    dispatch(fetchUserEvents());
  }, []);

  const columns = [
    {
      field: "name",
      headerName: "Event Name",
      flex: "flex-[1_1_0%]",
      renderCell: (row) => <span> {`${row.name}`} </span>,
    },

    {
      field: "description",
      headerName: "Description",
      flex: "flex-[1_1_0%]",
      renderCell: (row) => {
        return <span> {row.description} </span>;
      },
    },
    {
      field: "location",
      headerName: "Venue",
      flex: "flex-[1.5_1.5_0%]",
      renderCell: (row) => {
        return <span>{row.location}</span>;
      },
    },
    {
      field: "date",
      headerName: "Event Date",
      flex: "flex-[1.5_1.5_0%]",
      renderCell: (row) => {
        return <span>{`${new Date(row.date).toLocaleDateString()}`}</span>;
      },
    },
    
  ];
//   const handleEventDelete = (eventId) => {
//     dispatch(deleteEvent({ eventId }));
//   };

//   const handleDelete = (row) => {
//     ShowDialogBox({
//       title: "Delete Item",
//       text: "Are you sure you want to delete this item?",
//       icon: "warning",
//       confirmButtonText: "Yes, delete it!",
//       cancelButtonText: "Cancel", // Provide cancel button text
//       successTitle: "Deleted!",
//       successText: "The item has been deleted successfully.",
//       itemId: row._id,
//       deleteItem: handleEventDelete,
//     });
//   };
//   const handleEdit = (row) => {
//     navigate(`edit`, {
//       state: {
//         row: row,
//       },
//     });
//   };
  return (
    <MemberEventListing rows={events} columns={columns} loading={loading} />
  );
};

export default MemberEventListingWrapper;
