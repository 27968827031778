import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL, API_HEADER } from "./URL";

export const fetchTransactions = createAsyncThunk(
  "transactions/fetchTransactions",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_URL}/transaction`, API_HEADER);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteTransaction = createAsyncThunk(
  "transactions/deleteTransaction",
  async ({ transactionId }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${API_URL}/transaction/${transactionId}`,
        API_HEADER
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const contactSlice = createSlice({
  name: "transaction",

  initialState: {
    transactions: [],
    loading: false,
  },
  reducers: {},
  extraReducers: {
    [fetchTransactions.pending]: (state) => {
      state.loading = true;
    },
    [fetchTransactions.fulfilled]: (state, action) => {
      state.transactions = action.payload.transactions;
      state.loading = false;
    },
    [fetchTransactions.rejected]: (state) => {
      state.loading = false;
    },
    [deleteTransaction.fulfilled]: (state, action) => {
      state.transactions = state.transactions.filter(
        (item) => item._id !== action.payload.deleteTransactionId
      );
      state.loading = false;
    },
  },
});
// export const {} = authSlice.actions;

export default contactSlice.reducer;
