import React, { useEffect, useState } from "react";
// import UserLkisting from "./UserLkisting";
import { useNavigate } from "react-router-dom";
import { FaPencil } from "react-icons/fa6";
import { FaTrashAlt } from "react-icons/fa";
import ShowDialogBox from "../../components/ShowDialogBox/ShowDialogBox";
import { useDispatch, useSelector } from "react-redux";
import PackagesListing from "./packagesListing";
import {
  fetchBusiness,
  deleteBusiness,
} from "../../redux/slices/businessSlice";
import { fetchPackage, deletePackage } from "../../redux/slices/packageSlice";

const PackageListingWrapper = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { packages, loading } = useSelector((state) => state.package);

  useEffect(() => {
    dispatch(fetchPackage());
  }, []);

  // const rows = [
  //   {
  //     id: "1",
  //     name: "aayushi",
  //     email: "aayushisharma51097@gmail.com",
  //     mob: "9691735240",
  //     country: "India",
  //     updateDate: "10/12/2024",
  //   },
  //   {
  //     id: "2",
  //     name: "aayushi",
  //     email: "aayushisharma51097@gmail.com",
  //     mob: "9691735240",
  //     country: "India",
  //     updateDate: "10/12/2024",
  //   },
  //   {
  //     id: "3",
  //     name: "aayushi",
  //     email: "aayushisharma51097@gmail.com",
  //     mob: "9691735240",
  //     country: "India",
  //     updateDate: "10/12/2024",
  //   },
  // ];

  const columns = [
    {
      field: "name",
      headerName: "Package Name",
      flex: "flex-[1_1_0%]",
      renderCell: (row) => <span> {`${row.name}`} </span>,
    },
    {
      field: "description",
      headerName: "Description",
      flex: "flex-[1.5_1.5_0%]",
      renderCell: (row) => {
        return <span> {row.description} </span>;
      },
    },
    {
      field: "price",
      headerName: "Price",
      flex: "flex-[1_1_0%]",
      renderCell: (row) => {
        return <span> {`${row.price}`} </span>;
      },
    },
    {
      field: "tag",
      headerName: "Tag",
      flex: "flex-[1_1_0%]",
      renderCell: (row) => {
        return <span> {`${row.tag}`} </span>;
      },
    },

    {
      field: "actions",
      headerName: "Actions",
      flex: "flex-[0.5_0.5_0%]",
      renderCell: (row) => (
        <>
          <FaPencil
            onClick={() => handleEdit(row)}
            style={{ cursor: "pointer", marginRight: "8px" }}
          />
          <FaTrashAlt
            onClick={() => handleDelete(row)}
            style={{ cursor: "pointer", marginRight: "8px" }}
          />
        </>
      ),
      align: "end",
    },
  ];
  const handlePackageDelete = (packageId) => {
    dispatch(deletePackage({ packageId }));
  };

  const handleDelete = (row) => {
    ShowDialogBox({
      title: "Delete Item",
      text: "Are you sure you want to delete this item?",
      icon: "warning",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel", // Provide cancel button text
      successTitle: "Deleted!",
      successText: "The item has been deleted successfully.",
      itemId: row._id,
      deleteItem: handlePackageDelete,
    });
  };
  const handleEdit = (row) => {
    navigate(`edit/${row._id}`, {
      state: {
        row: row,
      },
    });
  };
  return (
    <PackagesListing rows={packages} columns={columns} loading={loading} />
  );
};

export default PackageListingWrapper;
