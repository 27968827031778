import React, { useEffect, useState } from "react";
// import UserLkisting from "./UserLkisting";
import { useNavigate } from "react-router-dom";
import ShowDialogBox from "../../components/ShowDialogBox/ShowDialogBox";
import { useDispatch, useSelector } from "react-redux";
import { FaTrashAlt } from "react-icons/fa";
import ContactListing from "./contactListing";
import { deleteContact, fetchContacts } from "../../redux/slices/ContactSlice";

const ContactListingWrapper = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { contacts, loading } = useSelector((state) => state.contact);

  useEffect(() => {
    dispatch(fetchContacts());
  }, []);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: "flex-[1_1_0%]",
      renderCell: (row) => <span> {`${row.firstName} ${row.lastName}`} </span>,
    },
    {
      field: "email",
      headerName: "Email",
      flex: "flex-[1.5_1.5_0%]",
      renderCell: (row) => {
        return <span> {row.email} </span>;
      },
    },
    {
      field: "phone",
      headerName: "Phone",
      flex: "flex-[1_1_0%]",
      renderCell: (row) => {
        return <span> {`${row.phone}`} </span>;
      },
    },
    {
      field: "message",
      headerName: "Message",
      flex: "flex-[1_1_0%]",
      renderCell: (row) => {
        return <span> {`${row.message}`} </span>;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: "flex-[0.5_0.5_0%]",
      renderCell: (row) => (
        <>
          <FaTrashAlt
            onClick={() => handleDelete(row)}
            style={{ cursor: "pointer", marginRight: "8px" }}
          />
        </>
      ),
      align: "end",
    },
  ];
  const handleContactDelete = (contactId) => {
    dispatch(deleteContact({ contactId }));
  };

  const handleDelete = (row) => {
    ShowDialogBox({
      title: "Delete Item",
      text: "Are you sure you want to delete this item?",
      icon: "warning",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel", // Provide cancel button text
      successTitle: "Deleted!",
      successText: "The item has been deleted successfully.",
      itemId: row._id,
      deleteItem: handleContactDelete,
    });
  };

  return <ContactListing rows={contacts} columns={columns} loading={loading} />;
};

export default ContactListingWrapper;
