import React, { useEffect, useState } from "react";
// import UserLkisting from "./UserLkisting";
import { useNavigate } from "react-router-dom";
import { FaEye, FaPencil } from "react-icons/fa6";
import { FaTrashAlt } from "react-icons/fa";
import ShowDialogBox from "../../components/ShowDialogBox/ShowDialogBox";
import { useDispatch, useSelector } from "react-redux";

import {
  changeBlogAccess,
  deleteBlog,
  fetchBlogs,
} from "../../redux/slices/BlogSlice";
import EventListing from "./EventListing";
import { deleteEvent, fetchEvents } from "../../redux/slices/EventSlice";

const EventListingWrapper = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { events, loading } = useSelector((state) => state.event);

  useEffect(() => {
    dispatch(fetchEvents());
  }, []);
  const handleStatusChange = (e, id) => {
    let data = {
      id: id,
      value: e.target.value,
    };
    dispatch(changeBlogAccess({ data }));
  };

  const columns = [
    {
      field: "name",
      headerName: "Event Name",
      flex: "flex-[1_1_0%]",
      renderCell: (row) => <span> {`${row.name}`} </span>,
    },

    {
      field: "description",
      headerName: "Description",
      flex: "flex-[1_1_0%]",
      renderCell: (row) => {
        return <span> {row.description} </span>;
      },
    },
    {
      field: "location",
      headerName: "Venue",
      flex: "flex-[1.5_1.5_0%]",
      renderCell: (row) => {
        return <span>{row.location}</span>;
      },
    },
    {
      field: "date",
      headerName: "Event Date",
      flex: "flex-[1.5_1.5_0%]",
      renderCell: (row) => {
        return <span>{`${new Date(row.date).toLocaleDateString()}`}</span>;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: "flex-[0.5_0.5_0%]",
      renderCell: (row) => (
        <>
          {}
          <FaPencil
            onClick={() => handleEdit(row)}
            style={{ cursor: "pointer", marginRight: "8px" }}
          />
          <FaTrashAlt
            onClick={() => handleDelete(row)}
            style={{ cursor: "pointer", marginRight: "8px" }}
          />
          <FaEye
            onClick={() =>
              navigate(`view`, {
                state: {
                  row: row,
                },
              })
            }
            style={{
              cursor: "pointer",
              marginRight: "8px",
            }}
          />
        </>
      ),
      align: "end",
    },
  ];
  const handleEventDelete = (eventId) => {
    dispatch(deleteEvent({ eventId }));
  };

  const handleDelete = (row) => {
    ShowDialogBox({
      title: "Delete Item",
      text: "Are you sure you want to delete this item?",
      icon: "warning",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel", // Provide cancel button text
      successTitle: "Deleted!",
      successText: "The item has been deleted successfully.",
      itemId: row._id,
      deleteItem: handleEventDelete,
    });
  };
  const handleEdit = (row) => {
    navigate(`edit`, {
      state: {
        row: row,
      },
    });
  };
  return <EventListing rows={events} columns={columns} loading={loading} />;
};

export default EventListingWrapper;
