import React from "react";
import { useLocation } from "react-router-dom";

const icon = (
  <>
    <svg
      className="w-4 h-4 fill-current text-gray-500 mr-2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <path
        fillRule="evenodd"
        d="M3 2a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H3zM2 4v12h16V4H2zm5 2a1 1 0 0 1 2 0v3a1 1 0 1 1-2 0V6zm4 0a1 1 0 0 1 2 0v3a1 1 0 1 1-2 0V6z"
      />
    </svg>
  </>
);

const ViewEvent = () => {
  const location = useLocation();
  const { row } = location.state;

  return (
    <div className=" p-2 m-4 bg-white shadow-lg rounded-lg overflow-hidden">
      <div className="px-6 py-4">
        <h2 className="text-3xl font-bold text-gray-800 mb-2">{row.name}</h2>
        <p className="text-gray-600 text-sm mb-4">{row.description}</p>
        <div className="flex items-center mb-2">
          {icon}
          <p className="text-gray-600 text-sm">
            <strong> Date:</strong> {new Date(row.date).toLocaleDateString()}
          </p>
        </div>
        <div className="flex items-center mb-2">
          {icon}
          <p className="text-gray-600 text-sm">
            <strong>Time:</strong> {row.time}
          </p>
        </div>
        <div className="flex items-center mb-2">
          {icon}
          <p className="text-gray-600 text-sm">
            <strong>Location:</strong> {row.location}
          </p>
        </div>
        <div className="flex items-center mb-2">
          {icon}

          <p className="text-gray-600 text-sm">
            <strong> Category:</strong> {row.category}
          </p>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-2 text-gray-800">
            Registered Users:
          </h3>
          <div className="grid grid-cols-1 sm:grid-cols-4 gap-4">
            {row.registeredUsers.map((user, index) => (
              <div key={index} className="bg-gray-100 p-4 rounded-lg w-fit">
                <h5 className="text-lg font-semibold mb-2">
                  {user?.user?.name}
                </h5>
                <h5 className="text-lg font-semibold mb-2">
                  {user.user.email}
                </h5>

                <p className="text-sm text-gray-600">
                  Notification: {user.eventNotification}
                </p>
                {/* Render other user details here */}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewEvent;
