import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_HEADER, API_URL } from "./URL";

export const fetchAdminProfile = createAsyncThunk(
  "auth/fetchAdminProfile",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_URL}/admin/profile`, API_HEADER);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchUserProfile = createAsyncThunk(
  "auth/fetchUserProfile",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${API_URL}/members/profile`,
        API_HEADER
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const renewPackage = createAsyncThunk(
  "member/renew",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${API_URL}/members/renew`,
        {},
        API_HEADER
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",

  initialState: {
    user: null,
    accessToken: null,
    refreshToken: "",
    admin: null,
    loading: false,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
    },
  },
  extraReducers: {
    [fetchAdminProfile.pending]: (state) => {
      state.loading = true;
    },
    [fetchAdminProfile.fulfilled]: (state, action) => {
      state.admin = action.payload;
      state.loading = false;
    },
    [fetchAdminProfile.rejected]: (state) => {
      state.loading = false;
    },
    [fetchUserProfile.pending]: (state) => {
      state.loading = true;
    },
    [fetchUserProfile.fulfilled]: (state, action) => {
      state.user = action.payload;
      state.loading = false;
    },
    [fetchUserProfile.rejected]: (state) => {
      state.loading = false;
    },
    [renewPackage.fulfilled]: (state, action) => {
      const { newValidTillDate } = action.payload;
      state.user.packageInformation.validTillDate = newValidTillDate;
      state.loading = false;
    },
  },
});

export const { setUser, setAccessToken, setRefreshToken, setAdmin } =
  authSlice.actions;

export default authSlice.reducer;
