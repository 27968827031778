import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import DashboardWrappper from "./pages/Dashboard/DashboardWrapper";
import UserListingWrapper from "./pages/users/list/UserListingWrapper";
import Layout from "./components/Layout/Layout";
import Layout1 from "./components/Layout/Layout1";
import BusinessListingWrapper from "./pages/business/businessListingWrapper";
import PackageListingWrapper from "./pages/packages/packagesListingWrapper";
import Profile from "./pages/profile/profile";
import Setting from "./pages/Setting/Setting";
import AddPackage from "./pages/packages/add/AddPackage";
import EditPackage from "./pages/packages/edit/EditPackage";
import ContactListingWrapper from "./pages/contactUs/contactListingWrapper";
import ClientLogin from "./pages/ClientLogin";
import Dashboard from "./pages/Dashboard/Dashboard";
import MemBusinessListingWrapper from "./pages/business/memberBusinessList/memBusinessListWrapper";
import AddMemBusiness from "./pages/business/memberBusinessList/addMemBusiness";
import EditMemBusiness from "./pages/business/memberBusinessList/EditMemBusiness";
import BcategoriesListingWrapper from "./pages/business categories/BcategoriesListingWrapper";
import EditCategories from "./pages/business categories/EditCategories";
import AddCategories from "./pages/business categories/AddCategories";
import BlogsListingWrapper from "./pages/blogs/blogsListWrapper";
import EditBlog from "./pages/blogs/EditBlog";
import AddBlog from "./pages/blogs/AddBlog";
import UserView from "./pages/users/view/UserView";
import MediaBlogsListingWrapper from "./pages/media blogs/mediaBlogListWrapper";
import AddMediaBlog from "./pages/media blogs/AddMediaBlog";
import EditMediaBlog from "./pages/media blogs/EditMediaBlog";
import TransactionListingWrapper from "./pages/transactions/transactionListWrapper";
import EventListingWrapper from "./pages/event/EventListingWrapper";
import AddEvent from "./pages/event/AddEvent";
import EditEvent from "./pages/event/EditEvent";
import MemberEventListingWrapper from "./pages/event/member Event/memEventListingWrapper";
import Subscription from "./pages/profile/Subscription";
import BlogcategoriesListingWrapper from "./pages/blogs-categories/BlogCategoreisListingWrapper";
import AddBlogCategories from "./pages/blogs-categories/AddBlogCategories";
import EditBlogsCategories from "./pages/blogs-categories/EditBlogCategories";
import EditUserWrapper from "./pages/users/edit/EditUserWrapper";
import EditBusiness from "./pages/business/EditBusiness";
import Payment from "./pages/Payment/Payment";
import Success from "./pages/Payment/Success";
import ViewEvent from "./pages/event/ViewEvent";
import ForgotPassword from "./pages/ForgotPassword";

const Routing = () => {
  let adminToken = localStorage.getItem("adminToken");
  let memberToken = localStorage.getItem("memberToken");
  useEffect(() => {
    memberToken = localStorage.getItem("memberToken");
    adminToken = localStorage.getItem("adminToken");
  }, [adminToken, memberToken]);

  const AdminPrivateRoute = ({ element, ...rest }) => {
    return adminToken && !memberToken ? (
      <Layout1>{element}</Layout1>
    ) : (
      <Navigate to="/admin/login" replace state={{ from: rest.location }} />
    );
  };
  const MemberPrivateRoute = ({ element, ...rest }) => {
    return memberToken && !adminToken ? (
      <Layout>{element}</Layout>
    ) : (
      <Navigate to="/login" replace state={{ from: rest.location }} />
    );
  };

  const LoginRoute = ({ element }) =>
    adminToken ? (
      <Navigate to="/dashboard" replace />
    ) : (
      <Login>{element}</Login>
    );
  const MemberLoginRoute = ({ element }) => {
    return memberToken && !adminToken ? (
      <Navigate to="/user/dashboard" replace />
    ) : (
      <ClientLogin>{element}</ClientLogin>
    );
  };
  const HomeRedirection = ({ element }) => {
    if (memberToken) {
      return <Navigate to="/user/dashboard" replace />;
    } else if (adminToken) {
      return <Navigate to="/dashboard" replace />;
    } else {
      return <Navigate to="/login" replace />;
    }
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/admin/login" element={<LoginRoute />} />

        <Route path="/login" element={<MemberLoginRoute />} />
        <Route path="/admin/forgotpassword" element={<ForgotPassword />} />

        <Route path="*" element={<HomeRedirection />} />

        {/* Member Privates Routes */}
        {memberToken && (
          <>
            <Route
              path="/user/dashboard"
              element={<MemberPrivateRoute element={<DashboardWrappper />} />}
            />
            <Route
              path="/user/business"
              element={
                <MemberPrivateRoute element={<MemBusinessListingWrapper />} />
              }
            />
            <Route
              path="/user/profile"
              element={<MemberPrivateRoute element={<Profile />} />}
            />
            <Route
              path="/user/events"
              element={
                <MemberPrivateRoute element={<MemberEventListingWrapper />} />
              }
            />
            <Route
              path="/user/setting"
              element={<MemberPrivateRoute element={<Setting />} />}
            />
            <Route
              path="/user/business/add"
              element={<MemberPrivateRoute element={<AddMemBusiness />} />}
            />
            <Route
              path="/user/business/edit"
              element={<MemberPrivateRoute element={<EditMemBusiness />} />}
            />
            <Route
              path="/user/subscription"
              element={<MemberPrivateRoute element={<Subscription />} />}
            />
            <Route
              path="/user/stripe/payment/:renewToken"
              element={<MemberPrivateRoute element={<Payment />} />}
            />
            <Route
              path="/user/success"
              element={<MemberPrivateRoute element={<Success />} />}
            />
            <Route path="*" element={<Navigate to="/user/dashboard" />} />
          </>
        )}
        {/* Admin Private Routes */}
        {adminToken && (
          <>
            <Route
              path="/"
              element={<AdminPrivateRoute element={<DashboardWrappper />} />}
            />
            <Route
              path="/dashboard"
              element={<AdminPrivateRoute element={<DashboardWrappper />} />}
            />
            <Route
              path="/members"
              element={<AdminPrivateRoute element={<UserListingWrapper />} />}
            />
            <Route
              path="/members/view"
              element={<AdminPrivateRoute element={<UserView />} />}
            />
            <Route
              path="/members/edit/:id"
              element={<AdminPrivateRoute element={<EditUserWrapper />} />}
            />

            <Route
              path="/business"
              element={
                <AdminPrivateRoute element={<BusinessListingWrapper />} />
              }
            />
            <Route
              path="/business/edit"
              element={<AdminPrivateRoute element={<EditBusiness />} />}
            />
            <Route
              path="/profile"
              element={<AdminPrivateRoute element={<Profile />} />}
            />
            <Route
              path="/packages"
              element={
                <AdminPrivateRoute element={<PackageListingWrapper />} />
              }
            />
            <Route
              path="/packages/add"
              element={<AdminPrivateRoute element={<AddPackage />} />}
            />
            <Route
              path="/packages/edit/:id"
              element={<AdminPrivateRoute element={<EditPackage />} />}
            />
            <Route
              path="/contacts"
              element={
                <AdminPrivateRoute element={<ContactListingWrapper />} />
              }
            />
            <Route
              path="/business-categories"
              element={
                <AdminPrivateRoute element={<BcategoriesListingWrapper />} />
              }
            />
            <Route
              path="/business-categories/add"
              element={<AdminPrivateRoute element={<AddCategories />} />}
            />
            <Route
              path="/business-categories/edit"
              element={<AdminPrivateRoute element={<EditCategories />} />}
            />
            <Route
              path="/blogs"
              element={<AdminPrivateRoute element={<BlogsListingWrapper />} />}
            />
            <Route
              path="/blogs-categories"
              element={
                <AdminPrivateRoute element={<BlogcategoriesListingWrapper />} />
              }
            />
            <Route
              path="/blogs-categories/add"
              element={<AdminPrivateRoute element={<AddBlogCategories />} />}
            />
            <Route
              path="/blogs-categories/edit"
              element={<AdminPrivateRoute element={<EditBlogsCategories />} />}
            />
            <Route
              path="/blogs/edit"
              element={<AdminPrivateRoute element={<EditBlog />} />}
            />
            <Route
              path="/blogs/add"
              element={<AdminPrivateRoute element={<AddBlog />} />}
            />

            <Route
              path="/transactions"
              element={
                <AdminPrivateRoute element={<TransactionListingWrapper />} />
              }
            />
            <Route
              path="/event"
              element={<AdminPrivateRoute element={<EventListingWrapper />} />}
            />
            <Route
              path="/event/add"
              element={<AdminPrivateRoute element={<AddEvent />} />}
            />
            <Route
              path="/event/edit"
              element={<AdminPrivateRoute element={<EditEvent />} />}
            />
            <Route
              path="/event/view"
              element={<AdminPrivateRoute element={<ViewEvent />} />}
            />

            <Route path="*" element={<Navigate to="/" />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
};

export default Routing;
