import React from "react";
import * as XLSX from "xlsx";

const ExportButton = ({ data, filename }) => {
  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, filename + ".xlsx");
  };

  return (
    <button
      className="bg-green-800 font-semibold text-white px-4  rounded-2xl"
      onClick={exportToExcel}
    >
      Export to Excel
    </button>
  );
};

export default ExportButton;
