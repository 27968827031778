import React from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { deleteMember } from "../../redux/slices/memberSlice";

const ShowDialogBox = (options) => {
  if (!options) {
    // Handle the case where options is not provided
    return null;
  }

  const {
    title,
    text,
    icon,
    showCancelButton,
    confirmButtonColor,
    cancelButtonColor,
    confirmButtonText,
    cancelButtonText,
    successTitle,
    successText,
    itemId,
    deleteItem,
  } = options;

  return Swal.fire({
    title,
    text,
    icon,
    showCancelButton,
    confirmButtonColor,
    cancelButtonColor,
    confirmButtonText,
    cancelButtonText, // Include the cancel button text
  }).then((result) => {
    if (result.isConfirmed) {
      deleteItem(itemId);
      Swal.fire({
        title: successTitle,
        text: successText,
        icon: "success",
      });
    }
  });
};

export default ShowDialogBox;
