import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL, API_HEADER } from "./URL";

export const fetchBlogs = createAsyncThunk(
  "blogs/fetchBlogs",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${API_URL}/blogs/for-admin`,
        API_HEADER
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const createBlog = createAsyncThunk(
  "blogs/createBlog",
  async ({ formDataForSubmission }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${API_URL}/blogs`,
        formDataForSubmission,
        API_HEADER
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const editBlog = createAsyncThunk(
  "blogs/editBlog",
  async ({ formDataForSubmission }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${API_URL}/blogs/${formDataForSubmission.get("_id")}`,
        formDataForSubmission,
        API_HEADER
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteBlog = createAsyncThunk(
  "blogs/deleteBlog",
  async ({ blogId }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${API_URL}/blogs/${blogId}`,
        API_HEADER,
        blogId
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const changeBlogAccess = createAsyncThunk(
  "blogs/changeBlogAccess",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${API_URL}/blogs/${data.id}/access`,
        data,
        API_HEADER
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchBlogsCategories = createAsyncThunk(
  "blogs/fetchBlogsCategories",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_URL}/blogs-categories`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const createBlogsCategories = createAsyncThunk(
  "blogs/createBlogsCategories",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${API_URL}/blogs-categories`,
        data,
        API_HEADER
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const editBlogsCategories = createAsyncThunk(
  "blogs/editBlogsCategories",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${API_URL}/blogs-categories/${data._id}`,
        data,
        API_HEADER
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteBlogsCategories = createAsyncThunk(
  "blogs/deleteBlogsCategories",
  async ({ categoryId }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${API_URL}/blogs-categories/${categoryId}`,
        API_HEADER,
        categoryId
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const blogSlice = createSlice({
  name: "blogs",

  initialState: {
    blogs: [],
    loading: false,
    categories: [],
  },
  reducers: {},
  extraReducers: {
    [fetchBlogs.pending]: (state) => {
      state.loading = true;
    },
    [fetchBlogs.fulfilled]: (state, action) => {
      state.blogs = action.payload.data;
      state.loading = false;
    },
    [fetchBlogs.rejected]: (state) => {
      state.loading = false;
    },
    [deleteBlog.fulfilled]: (state, action) => {
      state.blogs = state.blogs.filter(
        (item) => item._id !== action.payload.deleteblogId
      );
      state.loading = false;
    },
    [changeBlogAccess.fulfilled]: (state, action) => {
      const updatedBlog = state.blogs.map((blog) => {
        if (blog._id === action.payload.updatedBlogPost._id) {
          return action.payload.updatedBlogPost;
        } else {
          return blog;
        }
      });
      state.blogs = updatedBlog;
      state.loading = false;
    },
    [fetchBlogsCategories.fulfilled]: (state, action) => {
      state.categories = action.payload.categories;
    },
    [createBlogsCategories.fulfilled]: (state, action) => {
      state.categories.push(action.payload.category);
    },
    [deleteBlogsCategories.fulfilled]: (state, action) => {
      state.categories = state.categories.filter(
        (item) => item._id !== action.payload.categoryId
      );
      state.loading = false;
    },
  },
});
// export const {} = authSlice.actions;

export default blogSlice.reducer;
