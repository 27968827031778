import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useLogoutMutation } from "../../../services/authService";
import toast from "react-hot-toast";
import { WEB_URL } from "../../../redux/slices/URL";
import Cookies from "js-cookie";

const Logout = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const token =
    localStorage.getItem("adminToken") || localStorage.getItem("memberToken");

  const [logout] = useLogoutMutation();
  const handleLogout = () => {
    // logout();
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("adminToken");
    localStorage.removeItem("memberToken");
    const cookieNames = ["token", "userName", "email", "name"];
    cookieNames.forEach((cookieName) => {
      Cookies.remove(cookieName, { domain: "canadiancleaningassociation.com" });
      Cookies.remove(cookieName, { domain: "canadiancleaningassociation.ca" });
      Cookies.remove(cookieName, { domain: "localhost" });
      Cookies.remove(cookieName, {
        domain: "user.canadiancleaningassociation.com",
      });
    });
    window.location.replace("/");
  };

  const location = useLocation();
  const isLoginRoute = location.pathname === "/login";
  const isAdminLoginRoute = location.pathname === "/admin/login";
  const cookieEmail = Cookies.get("email");
  const cookieUserName = Cookies.get("userName");

  return (
    <div className="flex gap-4 justify-evenly sm:justify-end items-center px-4">
      <div>
        {!token ? (
          <div>
            {!isAdminLoginRoute && (
              <div className="flex flex-row gap-2">
                <button className="bg-green-300 text-black px-3 md:px-4 py-2 rounded-none hover:bg-cyan-900 hover:text-white text-xs md:text-sm">
                  <a href="https://www.canadiancleaningassociation.ca">
                    Back To Our Site
                  </a>
                </button>
                <button className="bg-green-300 text-black px-3 md:px-4 py-2 rounded-none hover:bg-cyan-900 hover:text-white text-xs md:text-sm">
                  <a href={`${WEB_URL}/become-member`}>Become a Member</a>
                </button>
              </div>
            )}
            {!isLoginRoute && (
              <div className="flex flex-row gap-2">
                <button className="bg-green-300 text-black px-3 md:px-4 py-2 rounded-none hover:bg-cyan-900 hover:text-white text-xs md:text-sm">
                  <a href="https://www.canadiancleaningassociation.ca">
                    Back To Our Site
                  </a>
                </button>{" "}
                <button
                  className="bg-green-300 text-black px-3 md:px-4 py-2 rounded-none hover:bg-cyan-900 hover:text-white text-xs md:text-sm"
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  Member Login
                </button>
              </div>
            )}
          </div>
        ) : (
          <div className="flex">
            {localStorage.getItem("memberToken") && (
              <div className="bg-cyan-900 text-white rounded-2xl p-3 mx-2">
                {`Logged In- ${cookieUserName ? cookieUserName : cookieEmail}
              `}
              </div>
            )}
            <button
              className="bg-green-300 text-black px-3 md:px-4 py-2 rounded-none hover:bg-cyan-900 hover:text-white text-xs md:text-sm"
              onClick={handleLogout}
            >
              Logout
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Logout;
