import React, { useEffect, useState } from "react";
import UserLkisting from "./UserLkisting";
import { useNavigate } from "react-router-dom";
import { FaPencil } from "react-icons/fa6";
import { FaTrashAlt, FaEye } from "react-icons/fa";
import ShowDialogBox from "../../../components/ShowDialogBox/ShowDialogBox";
import { useDispatch, useSelector } from "react-redux";
import {
  changeUserStatus,
  deleteMember,
  fetchMembers,
} from "../../../redux/slices/memberSlice";

const UserListingWrapper = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { members, loading } = useSelector((state) => state.member);

  useEffect(() => {
    dispatch(fetchMembers());
  }, []);

  const handleUserStatusChange = (e, id) => {
    let data = {
      id: id,
      value: e.target.value,
    };
    dispatch(changeUserStatus({ data }));
  };

  const columns = [
    {
      field: "UserName",
      headerName: "User Name",
      flex: "flex-[1_1_0%]",
      renderCell: (row) => (
        <span>
          {" "}
          {`${row?.contactInfo?.firstName} ${row?.contactInfo?.lastName}`}{" "}
        </span>
      ),
    },
    {
      field: "emailAddress",
      headerName: "Email",
      flex: "flex-[1.5_1.5_0%]",
      renderCell: (row) => {
        return <span> {row?.contactInfo?.emailAddress} </span>;
      },
    },
    {
      field: "mobile",
      headerName: "Mobile no.",
      flex: "flex-[1_1_0%]",
      renderCell: (row) => {
        return <span> {`${row?.contactInfo?.cellPhone}`} </span>;
      },
    },
    // {
    //   field: "Payment",
    //   headerName: "Payment Status",
    //   flex: "flex-[1_1_0%]",
    //   renderCell: (row) => {
    //     return (
    //       <span
    //         className={`p-1 ${
    //           row.packageInformation.paymentSuccess
    //             ? "bg-green-300"
    //             : "bg-red-300"
    //         }`}
    //       >
    //         {row.packageInformation.paymentSuccess ? "Paid" : "Unpaid"}
    //       </span>
    //     );
    //   },
    // },
    {
      field: "status",
      headerName: "Active/Inactive",
      flex: "flex-[1_1_0%]",
      renderCell: (row) => {
        return (
          <span>
            <select
              name=""
              id=""
              value={row.status}
              onChange={(e) => handleUserStatusChange(e, row._id)}
            >
              <option value="true">Active</option>
              <option value="false">Inactive</option>
            </select>
          </span>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: "flex-[0.5_0.5_0%]",
      renderCell: (row) => (
        <>
          <FaPencil
            onClick={() => handleEdit(row)}
            style={{
              cursor: "pointer",
              marginRight: "10px",
              fontSize: "1.2rem",
            }}
          />
          <FaTrashAlt
            onClick={() => handleDelete(row)}
            style={{
              cursor: "pointer",
              marginRight: "10px",
              fontSize: "1.2rem",
            }}
          />
          <FaEye
            onClick={() =>
              navigate(`view`, {
                state: {
                  row: row,
                },
              })
            }
            style={{
              cursor: "pointer",
              marginRight: "10px",
              fontSize: "1.2rem",
            }}
          />
        </>
      ),
      align: "end",
    },
  ];
  const handleMemberDelete = (memberId) => {
    dispatch(deleteMember({ memberId }));
  };

  const handleDelete = (row) => {
    ShowDialogBox({
      title: "Delete Item",
      text: "Are you sure you want to delete this item?",
      icon: "warning",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel", // Provide cancel button text
      successTitle: "Deleted!",
      successText: "The item has been deleted successfully.",
      itemId: row._id,
      deleteItem: handleMemberDelete,
    });
  };
  const handleEdit = (row) => {
    navigate(`edit/${row._id}`, {
      state: {
        row: row,
      },
    });
  };
  return <UserLkisting rows={members} columns={columns} loading={loading} />;
};

export default UserListingWrapper;
