import React from 'react'
import Dashboard from './Dashboard'
import Layout from '../../components/Layout/Layout'

const DashboardWrappper = () => {
    return (
        <>
          
        <Dashboard />
           
        </>
    )
}

export default DashboardWrappper