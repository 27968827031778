import React, { useEffect, useState } from "react";
// import UserLkisting from "./UserLkisting";
import { useNavigate } from "react-router-dom";
import { FaPencil } from "react-icons/fa6";
import { FaTrashAlt } from "react-icons/fa";
import ShowDialogBox from "../../components/ShowDialogBox/ShowDialogBox";
import { useDispatch, useSelector } from "react-redux";

import BlogsListing from "./blogsListing";
import {
  changeBlogAccess,
  deleteBlog,
  fetchBlogs,
} from "../../redux/slices/BlogSlice";

const BlogsListingWrapper = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { blogs, loading } = useSelector((state) => state.blogs);

  useEffect(() => {
    dispatch(fetchBlogs());
  }, []);
  const handleStatusChange = (e, id) => {
    let data = {
      id: id,
      value: e.target.value,
    };
    dispatch(changeBlogAccess({ data }));
  };

  const columns = [
    {
      field: "title",
      headerName: "Title",
      flex: "flex-[1_1_0%]",
      renderCell: (row) => <span> {`${row.title}`} </span>,
    },
    {
      field: "category",
      headerName: "Category",
      flex: "flex-[1_1_0%]",
      renderCell: (row) => (
        <span> {`${row.category ? row.category : "N/A"}`} </span>
      ),
    },

    {
      field: "createdAt",
      headerName: "Publish date",
      flex: "flex-[1_1_0%]",
      renderCell: (row) => {
        return (
          <span> {`${new Date(row.publishDate).toLocaleDateString()}`} </span>
        );
      },
    },
    {
      field: "forPremiumUser",
      headerName: "For Premium User",
      flex: "flex-[1.5_1.5_0%]",
      renderCell: (row) => {
        return (
          <span>
            <select
              onChange={(e) => handleStatusChange(e, row._id)}
              value={row.forPremiumUser}
            >
              <option value={true}>YES</option>
              <option value={false}>NO</option>
            </select>
          </span>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: "flex-[0.5_0.5_0%]",
      renderCell: (row) => (
        <>
          {}
          <FaPencil
            onClick={() => handleEdit(row)}
            style={{ cursor: "pointer", marginRight: "8px" }}
          />
          <FaTrashAlt
            onClick={() => handleDelete(row)}
            style={{ cursor: "pointer", marginRight: "8px" }}
          />
        </>
      ),
      align: "end",
    },
  ];
  const handleBusinessDelete = (blogId) => {
    dispatch(deleteBlog({ blogId }));
  };

  const handleDelete = (row) => {
    ShowDialogBox({
      title: "Delete Item",
      text: "Are you sure you want to delete this item?",
      icon: "warning",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel", // Provide cancel button text
      successTitle: "Deleted!",
      successText: "The item has been deleted successfully.",
      itemId: row._id,
      deleteItem: handleBusinessDelete,
    });
  };
  const handleEdit = (row) => {
    navigate(`edit`, {
      state: {
        row: row,
      },
    });
  };
  return <BlogsListing rows={blogs} columns={columns} loading={loading} />;
};

export default BlogsListingWrapper;
