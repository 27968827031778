import React, { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createBlogsCategories } from "../../redux/slices/BlogSlice";

const AddBlogCategories = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isAnyFieldEmpty = Object.values(formData).some(
      (value) => value === ""
    );

    if (isAnyFieldEmpty) {
      toast.error("Please fill out all fields.");
    } else {
      let data = { ...formData };
      dispatch(createBlogsCategories({ data }))
        .then((res) => {
          if (res.payload.success) {
            toast.success("Added");
          } else {
            toast.error(res.payload.error);
          }
        })
        .catch((error) => toast.error(error.message));
      navigate("/blogs-categories");
    }
  };

  return (
    <>
      <div className="max-w-2xl mx-auto bg-white rounded p-4 shadow-md">
        <h2 className="text-2xl font-semibold ">Add Category</h2>

        <form onSubmit={handleSubmit} className="flex flex-wrap -mx-2">
          <div className="mb-4 w-full md:w-1/2 px-2">
            <label
              htmlFor="name"
              className="block text-gray-600 text-sm font-medium mb-2"
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className="w-full border rounded-md py-2 px-3 focus:outline-none focus:ring focus:border-blue-300"
            />
          </div>

          <div className="w-full px-2 mt-6">
            <button
              type="submit"
              className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddBlogCategories;
