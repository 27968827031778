import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AiOutlineFileDone } from "react-icons/ai";
import { MdFreeCancellation, MdOutlinePendingActions } from "react-icons/md";
import { LiaBusinessTimeSolid } from "react-icons/lia";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BiCategoryAlt } from "react-icons/bi";
import { HiOutlineUserGroup } from "react-icons/hi2";
import { CiBoxList } from "react-icons/ci";

import {
  faFacebook,
  faTwitter,
  faLinkedin,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import {
  faChevronUp,
  faChevronDown,
  faChartBar,
  faCode,
  faCoffee,
  faBook,
  faAward,
} from "@fortawesome/free-solid-svg-icons";
import {
  fetchDashboard,
  fetchUserDashboard,
} from "../../redux/slices/DashboardSlice";
import {
  fetchAdminProfile,
  fetchUserProfile,
} from "../../redux/slices/AuthSlice";
import { BASE_URL } from "../../redux/slices/URL";

const GridItem = ({ icon, heading, certificate }) => {
  return certificate ? (
    <a
      className="flex flex-col items-center border p-4"
      target="_blank"
      rel="noreferrer"
      href={certificate}
    >
      <FontAwesomeIcon icon={icon} size="2x" />
      <p>{heading}</p>
    </a>
  ) : (
    <div className="flex flex-col items-center border p-4">
      <FontAwesomeIcon icon={icon} size="2x" />
      <p>{heading}</p>
    </div>
  );
};

const AccordionItem = ({ title, gridItems, certificate }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={`mb-4 border w-full overflow-hidden transition-height duration-300 ${
        isOpen ? "bg-white text-cyan-900 shadow-md" : "bg-cyan-900 text-white"
      }`}
    >
      <div
        className="p-4 flex justify-between items-center cursor-pointer"
        onClick={toggleAccordion}
      >
        <p className="text-lg">{title}</p>
        <FontAwesomeIcon
          icon={isOpen ? faChevronUp : faChevronDown}
          className={`ml-2 ${
            isOpen ? "text-cyan-900 rotate-180" : "text-white"
          }`}
        />
      </div>
      {isOpen && (
        <div className="p-4">
          <div className="grid grid-cols-4 flex-wrap gap-0">
            {gridItems.map((item, index) => {
              if (item.heading === "Certificate" && !certificate) {
                return <></>;
              }
              return (
                <GridItem
                  key={index}
                  icon={item.icon}
                  heading={item.heading}
                  className="custom-grid-item"
                  certificate={
                    item.heading === "Certificate" ? certificate : null
                  }
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
const faqData = [
  // {
  //   title: "Business Resources, Tools and Forms",
  //   gridItems: [
  //     { icon: faCoffee, heading: "Useful Business Forms Here" },
  //     { icon: faCode, heading: "Read Blog Post" },
  //   ],
  // },
  // {
  //   title: "UPCA Member Benefits and Partnerships",
  //   gridItems: [
  //     { icon: faCoffee, heading: "Item 01" },
  //     { icon: faCode, heading: "Item 02" },
  //     { icon: faChartBar, heading: "Item 03" },
  //     { icon: faBook, heading: "Item 04" },
  //     { icon: faCoffee, heading: "Item 05" },
  //     { icon: faCode, heading: "Item 06" },
  //     { icon: faChartBar, heading: "Item 07" },
  //     { icon: faBook, heading: "Item 08" },
  //   ],
  // },
  {
    title: "Member Ship Certificate",
    gridItems: [{ icon: faAward, heading: "Certificate" }],
  },
];

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const adminToken = localStorage.getItem("adminToken");
  const { dashboard, loading } = useSelector((state) => state.dashboard);
  const { user } = useSelector((state) => state.auth);
  const {
    businessCount,
    categoryCount,
    memberCount,
    packageCount,
    approvedBusinessCount,
    rejectedBusinessCount,
    pendingBusinessCount,
  } = dashboard;

  useEffect(() => {
    if (adminToken) {
      dispatch(fetchDashboard());
      dispatch(fetchAdminProfile());
    } else {
      dispatch(fetchUserDashboard());
      dispatch(fetchUserProfile());
    }
  }, []);

  return (
    <>
      <div className={`p-4 `}>
        {!adminToken && (
          <>
            <div className="bg-white p-8 rounded shadow-sm flex flex-col mb-4">
              <span className="self-end">
                <a
                  href="https://www.facebook.com/"
                  className="social text-primary-main mr-2 border p-3 rounded-full"
                >
                  <FontAwesomeIcon icon={faFacebook} size="1x" />
                </a>
                <a
                  href="https://www.twitter.com/"
                  className="social text-primary-main mr-2 border p-3 rounded-full"
                >
                  <FontAwesomeIcon icon={faTwitter} size="1x" />
                </a>
                <a
                  href="https://www.linkedin.com/"
                  className="social text-primary-main mr-2 border p-3 rounded-full"
                >
                  <FontAwesomeIcon icon={faLinkedin} size="1x" />
                </a>
                <a
                  href="https://www.instagram.com/"
                  className="social text-primary-main mr-2 border p-3 rounded-full"
                >
                  <FontAwesomeIcon icon={faInstagram} size="1x" />
                </a>
              </span>
            </div>
            <div className="bg-white p-8 rounded shadow-sm flex flex-col mb-4">
              <h2 className="text-xl text-primary-main font-semibold mb-4">
                Dashboard
              </h2>
              <div className="flex flex-col items-center justify-center container mx-auto pt-4">
                {faqData.map((item, index) => (
                  <AccordionItem
                    key={index}
                    title={item.title}
                    gridItems={item.gridItems}
                    certificate={
                      user?.certificate
                        ? `${BASE_URL}/uploads/${user?.certificate}`
                        : null
                    }
                  />
                ))}
              </div>
            </div>
          </>
        )}
        <div className=" p-8 rounded shadow-sm flex flex-col mb-5">
          <div className="mb-6">
            <h2 className="text-xl  text-primary-mainfont-semibold mb-4">
              Business Overview
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
              <div
                onClick={() =>
                  navigate(`${adminToken ? "/business" : "/user/business"}`)
                }
                className="bg-gray-100 shadow-xl rounded-2xl gap-8 flex flex-row justify-center items-center p-4 w-full mb-4 sm:mb-0 cursor-pointer"
              >
                <div>
                  <p className="text-gray-600 font-bold">Total Businesses</p>
                  <p className="text-3xl">{businessCount}</p>
                </div>
                <div className="text-5xl">
                  <LiaBusinessTimeSolid />
                </div>
              </div>
              <div
                onClick={() =>
                  navigate(
                    `${
                      adminToken
                        ? "/business?filter=approved"
                        : "/user/business?filter=approved"
                    }`
                  )
                }
                className="bg-gray-100 shadow-xl rounded-2xl gap-8 flex flex-row justify-center items-center p-4 w-full mb-4 sm:mb-0 cursor-pointer"
              >
                <div>
                  <p className="text-gray-600 font-bold">Approved Businesses</p>
                  <p className="text-3xl ">{approvedBusinessCount}</p>
                </div>
                <div className="text-5xl">
                  <AiOutlineFileDone />
                </div>
              </div>
              <div
                onClick={() =>
                  navigate(
                    `${
                      adminToken
                        ? "/business?filter=rejected"
                        : "/user/business?filter=rejected"
                    }`
                  )
                }
                className="bg-gray-100 shadow-xl rounded-2xl gap-8 flex flex-row justify-center items-center p-4 w-full mb-4 sm:mb-0 cursor-pointer"
              >
                <div>
                  <p className="text-gray-600 font-bold">Rejected Businesses</p>
                  <p className="text-3xl ">{rejectedBusinessCount}</p>
                </div>
                <div className="text-5xl">
                  <MdFreeCancellation />
                </div>
              </div>
              <div
                onClick={() =>
                  navigate(
                    `${
                      adminToken
                        ? "/business?filter=pending"
                        : "/user/business?filter=pending"
                    }`
                  )
                }
                className="bg-gray-100 shadow-xl rounded-2xl gap-8 flex flex-row justify-center items-center p-4 w-full mb-4 sm:mb-0 cursor-pointer"
              >
                <div>
                  <p className="text-gray-600 font-bold">Pending Businesses</p>
                  <p className="text-3xl">{pendingBusinessCount}</p>
                </div>
                <div className="text-5xl">
                  <MdOutlinePendingActions />
                </div>
              </div>
            </div>
          </div>
        </div>

        {adminToken && (
          <>
            <div className=" p-8 rounded shadow-sm flex flex-col ">
              <div className="flex flex-col sm:flex-row justify-evenly">
                <div className="mb-6 sm:mr-4">
                  <h2 className="text-xl text-center font-semibold mb-4">
                    Category Stats
                  </h2>
                  <div
                    onClick={() => navigate("/business-categories")}
                    className="bg-gray-100 shadow-xl gap-8 rounded-2xl flex flex-row justify-center items-center p-8 w-fit mb-4 sm:mb-0 cursor-pointer"
                  >
                    <div className="">
                      <p className="text-gray-600 font-bold pb-4">
                        Total Categories
                      </p>
                      <p className="text-3xl ">{categoryCount}</p>
                    </div>
                    <div className="text-5xl">
                      <BiCategoryAlt />
                    </div>
                  </div>
                </div>
                <div className="mb-6 sm:mr-4">
                  <h2 className="text-xl text-center font-semibold mb-4">
                    Member Stats
                  </h2>
                  <div
                    onClick={() => navigate("/members")}
                    className="bg-gray-100 shadow-xl gap-8 rounded-2xl flex flex-row justify-center items-center  p-8 w-fit mb-4 sm:mb-0 cursor-pointer"
                  >
                    <div>
                      <p className="text-gray-600 font-bold pb-4">
                        Total Members
                      </p>
                      <p className="text-3xl">{memberCount}</p>
                    </div>
                    <div className="text-5xl">
                      <HiOutlineUserGroup />
                    </div>
                  </div>
                </div>
                <div className="mb-6 sm:mr-4 ">
                  <h2 className="text-xl text-center font-semibold mb-4">
                    Package Stats
                  </h2>
                  <div
                    onClick={() => navigate("/packages")}
                    className="bg-gray-100 shadow-xl gap-8 rounded-2xl flex flex-row justify-center items-center p-8 w-fit mb-4 sm:mb-0 cursor-pointer"
                  >
                    <div>
                      <p className="text-gray-600 font-bold pb-4">
                        Total Packages
                      </p>
                      <p className="text-3xl ">{packageCount}</p>
                    </div>
                    <div className="text-5xl">
                      <CiBoxList />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Dashboard;
