import { configureStore } from "@reduxjs/toolkit";
// import { setupListeners } from "@reduxjs/toolkit/query";
// import apiSlice from "../../services/apiSlice";
// import { authMiddleware } from "../../utils";
import AuthSlice from "./AuthSlice";
import Sidenavlayoutslice from "./Sidenavlayoutslice";
import memberSlice from "./memberSlice";
import businessSlice from "./businessSlice";
import packageSlice from "./packageSlice";
import ContactSlice from "./ContactSlice";
import DashboardSlice from "./DashboardSlice";
import BlogsSlice from "./BlogSlice";
import MediaBlogSlice from "./MediaBlogSlice";
import TransactionSlice from "./TransactionSlice";
import EventSlice from "./EventSlice";

const store = configureStore({
  reducer: {
    auth: AuthSlice,
    sideNavLayout: Sidenavlayoutslice,
    member: memberSlice,
    business: businessSlice,
    package: packageSlice,
    contact: ContactSlice,
    dashboard: DashboardSlice,
    blogs: BlogsSlice,
    mblogs: MediaBlogSlice,
    transaction: TransactionSlice,
    event: EventSlice,
  },
});
export default store;
