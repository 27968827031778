import React, { useEffect, useState } from "react";
import { BiUser, BiEnvelope, BiLock, BiLockAlt } from "react-icons/bi";
import "./profile.css";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAdminProfile,
  fetchUserProfile,
  setAdmin,
} from "../../redux/slices/AuthSlice";
import axios from "axios";
import { API_HEADER, API_URL } from "../../redux/slices/URL";
import toast from "react-hot-toast";
import Loader from "../../components/Loader/Loader";
import { FaCloudUploadAlt } from "react-icons/fa";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";

const Profile = () => {
  const dispatch = useDispatch();
  const adminToken = localStorage.getItem("Token");
  const { admin, user, loading } = useSelector((state) => state.auth);
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [adminName, setAdminName] = useState(admin?.name);
  const [adminNewEmail, setAdminNewEmail] = useState(admin?.email);
  const [formData, setFormData] = useState(user);
  if (user && !formData) {
    setFormData(user);
  }
  const [validationEmailMemberInfo, setValidationEmailMemberInfo] =
    useState("");
  const [validationEmailContactInfo, setValidationEmailContactInfo] =
    useState("");
  const [validateTelephone, setValidateTelephone] = useState("");
  const [validateFax, setValidateFax] = useState("");
  const [validateCellPhone, setValidateCellPhone] = useState("");
  const [validateHomePhone, setValidateHomePhone] = useState("");
  const [validateWebsite, setValidateWebsite] = useState("");
  const [validatePostalContactInfo, setValidatePostalContactInfo] =
    useState("");
  const [validatePostalMemberInfo, setValidatePostalMemberInfo] = useState("");

  const handleChangeAdminPassword = async (e) => {
    try {
      const response = await axios.patch(
        `${API_URL}/admin/changePassword`,
        { email: admin.email, oldPassword: oldPass, newPassword: newPass },
        API_HEADER
      );
      if (response?.data?.success) {
        toast.success(response?.data?.message);
        setOldPass("");
        setNewPass("");
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };
  const handleChangeMemberPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.patch(
        `${API_URL}/members/changePassword`,
        { oldPassword: oldPass, newPassword: newPass },
        API_HEADER
      );
      if (response?.data?.success) {
        toast.success(response?.data?.message);
        setOldPass("");
        setNewPass("");
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };
  const handleUpdateAdminProfile = async (e) => {
    try {
      if (adminName || adminNewEmail) {
        const response = await axios.put(
          `${API_URL}/admin/updateProfile`,
          {
            email: admin.email,
            newEmail: adminNewEmail ? adminNewEmail : admin.email,
            name: adminName,
          },
          API_HEADER
        );
        if (response.data.success) {
          toast.success("Updated");
          window.location.reload();
        }
      } else {
        toast.error("No changes");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };
  const daysBetweenDates = (targetDate) => {
    const targetDateObj = new Date(targetDate);

    const today = new Date();

    const timeDifference = targetDateObj.getTime() - today.getTime();

    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    return daysDifference;
  };
  useEffect(() => {
    localStorage.getItem("adminToken")
      ? dispatch(fetchAdminProfile())
      : dispatch(fetchUserProfile());
  }, [dispatch]);

  const handlecontactInfoChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      contactInfo: {
        ...prevData.contactInfo,
        [name]: value,
      },
    }));
  };
  const handleMemberInformationChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      memberInformation: {
        ...prevData.memberInformation,
        [name]: value,
      },
    }));
  };
  const handleImageUpload = async (e) => {
    try {
      const formData = new FormData();
      formData.append("logo", e?.target?.files[0]);
      const imageUplaodResponse = await axios.post(
        `${API_URL}/members/logoUpload`,
        formData,
        API_HEADER
      );
      if (imageUplaodResponse.data.success) {
        const { name } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          memberInformation: {
            ...prevData.memberInformation,
            [name]: imageUplaodResponse?.data?.filePath,
          },
        }));

        toast.success("logo uploaded");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const handleCountryChangeoption = (val) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      contactInfo: {
        ...prevFormData.contactInfo,
        country: val,
      },
    }));
  };
  const handleRegionChangeption = (val) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      contactInfo: {
        ...prevFormData.contactInfo,
        territory: val,
      },
    }));
  };
  const handleCountryChangeoptionmem = (val) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      memberInformation: {
        ...prevFormData.memberInformation,
        country: val,
      },
    }));
  };
  const handleRegionChangeptionmem = (val) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      memberInformation: {
        ...prevFormData.memberInformation,
        province: val,
      },
    }));
  };
  // ==================================Validations=====================================
  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };
  const validateEmailMemberInfo = debounce((e) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+$/;
    if (e.target.value.trim() === "") {
      setValidationEmailMemberInfo("");
    } else if (!regex.test(e.target.value)) {
      setValidationEmailMemberInfo("Invalid Email");
    } else {
      setValidationEmailMemberInfo("");
    }
  }, 1000);

  const validateEmailContactInfo = debounce((e) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+$/;
    if (e.target.value.trim() === "") {
      setValidationEmailContactInfo("");
    } else if (!regex.test(e.target.value)) {
      setValidationEmailContactInfo("Invalid Email");
    } else {
      setValidationEmailContactInfo("");
    }
  }, 1000);
  const validatePostalContactInfoFn = debounce((e) => {
    const regex = /^(?:\d{5}(?:-\d{4})?|\b[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d)$/;
    if (e.target.value.trim() === "") {
      setValidatePostalContactInfo("");
    } else if (!regex.test(e.target.value)) {
      setValidatePostalContactInfo("Invalid Postal Code");
    } else {
      setValidatePostalContactInfo("");
    }
  }, 1000);
  const validatePostalMemberInfoFn = debounce((e) => {
    const regex = /^(?:\d{5}(?:-\d{4})?|\b[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d)$/;
    if (e.target.value.trim() === "") {
      setValidatePostalMemberInfo("");
    } else if (!regex.test(e.target.value)) {
      setValidatePostalMemberInfo("Invalid Postal Code");
    } else {
      setValidatePostalMemberInfo("");
    }
  }, 1000);

  const validateTelephoneFn = debounce((e) => {
    const telephoneRegex = /^(\+?\d{2})?(\d{10})$/;

    if (e.target.value.trim() === "") {
      setValidateTelephone("");
    } else if (!telephoneRegex.test(e.target.value)) {
      setValidateTelephone("Invalid telephone");
    } else {
      setValidateTelephone("");
    }
  }, 1000);

  const validateFaxFn = debounce((e) => {
    const faxRegex = /^(\+?\d{2})?(\d{10})$/;
    if (e.target.value.trim() === "") {
      setValidateFax("");
    } else if (!faxRegex.test(e.target.value)) {
      setValidateFax("Invalid Fax");
    } else {
      setValidateFax("");
    }
  }, 1000);

  const validateCellPhoneFn = debounce((e) => {
    const cellPhoneRegex =
      /^(?:\+1)?(?:\d{10}|\d{3}-\d{3}-\d{4}|\(\d{3}\)\s*\d{3}-\d{4}|\d{3}\.\d{3}\.\d{4})$/;
    if (e.target.value.trim() === "") {
      setValidateCellPhone("");
    } else if (!cellPhoneRegex.test(e.target.value)) {
      setValidateCellPhone("Invalid CellPhone");
    } else {
      setValidateCellPhone("");
    }
  }, 1000);

  const validateHomePhoneFn = debounce((e) => {
    const homePhoneRegex =
      /^(?:\+1)?(?:\d{10}|\d{3}-\d{3}-\d{4}|\(\d{3}\)\s*\d{3}-\d{4}|\d{3}\.\d{3}\.\d{4})$/;
    if (e.target.value.trim() === "") {
      setValidateHomePhone("");
    } else if (!homePhoneRegex.test(e.target.value)) {
      setValidateHomePhone("Invalid HomePhone");
    } else {
      setValidateHomePhone("");
    }
  }, 1000);
  const validateWebsiteFn = debounce((e) => {
    const WebsiteRegex =
      /^(ftp|http|https):\/\/([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/;
    if (e.target.value.trim() === "") {
      setValidateWebsite("");
    } else if (!WebsiteRegex.test(e.target.value)) {
      setValidateWebsite("Invalid Website");
    } else {
      setValidateWebsite("");
    }
  }, 1000);

  // =====================================================================================
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      validationEmailMemberInfo ||
      validationEmailContactInfo ||
      validateTelephone ||
      validateCellPhone ||
      validateFax ||
      validateHomePhone ||
      validateWebsite
    ) {
      toast.error("Fill Valid Details");
      return;
    }
    try {
      const response = await axios.put(
        `${API_URL}/members/update/${user._id}`,
        formData,
        API_HEADER
      );
      if (response.data.success) {
        toast.success(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      {loading ? (
        <div className="flex justify-center m-2 ">
          <Loader />
        </div>
      ) : (
        <div className="bg-white p-8 rounded shadow-sm mb-4 mt-4 ml-4">
          <div className="card-body">
            <h2 className="card-title mb-4 font-bold text-primary-main">
              {`${user ? `User Profile` : "Admin Profile"}`}
            </h2>
            {admin ? (
              <div className="flex flex-row md:flex-row my-4 w-4/5">
                <div className="mb-4 md:mb-0  flex flex-col md:flex-grow md:mr-4">
                  <div className="mb-4 flex items-center">
                    <span className="input-group-text bg-cyan-900 text-light">
                      <BiUser />
                    </span>
                    <label
                      htmlFor="name"
                      className="block mb-1 text-primary text-sm  sm:text-base font-semibold"
                    >
                      Name:
                    </label>
                    <div className="flex-grow ml-2">
                      <input
                        type="text"
                        className="w-5/6 md:w-full lg:w-full rounded-md px-2 py-1 border-2 hover:border-2 hover:border-primary border-gray-300"
                        id="name"
                        value={adminName ? adminName : admin.name}
                        onChange={(e) => setAdminName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mb-4 flex items-center">
                    <span className="input-group-text bg-cyan-900 text-light">
                      <BiEnvelope />
                    </span>
                    <label
                      htmlFor="email"
                      className="block mb-1 text-primary text-sm  sm:text-base font-semibold"
                    >
                      Email:
                    </label>
                    <div className="flex-grow ml-2">
                      <input
                        type="email"
                        className="w-5/6 md:w-full lg:w-full rounded-md px-2 py-1 border-2 hover:border-2 hover:border-primary border-gray-300"
                        id="email"
                        value={adminNewEmail ? adminNewEmail : admin.email}
                        onChange={(e) => setAdminNewEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-4 md:mb-0 flex flex-col">
                  <div className="mb-4 flex items-center">
                    <span className="input-group-text bg-cyan-900 text-light">
                      <BiLock />
                    </span>
                    <label
                      htmlFor="oldPassword"
                      className="block mb-1 text-primary text-sm  sm:text-base font-semibold"
                    >
                      Old Password:
                    </label>
                    <div className="ml-2">
                      <input
                        type="password"
                        className="w-5/6 md:w-full lg:w-full rounded-md px-2 py-1 border-2 hover:border-2 hover:border-primary border-gray-300"
                        id="oldPassword"
                        placeholder="Enter old password"
                        required
                        onChange={(e) => {
                          setOldPass(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="mb-4 flex items-center">
                    <span className="input-group-text bg-primary text-light">
                      <BiLockAlt />
                    </span>
                    <label
                      htmlFor="newPassword"
                      className="block mb-1 text-primary text-sm  sm:text-base font-semibold"
                    >
                      New Password:
                    </label>
                    <div className="ml-2">
                      <input
                        type="password"
                        className="w-5/6 md:w-full lg:w-full rounded-md px-2 py-1 border-2 hover:border-2 hover:border-primary border-gray-300"
                        id="newPassword"
                        placeholder="Enter new password"
                        required
                        onChange={(e) => {
                          setNewPass(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="step">
                  <h3 className=" mb-5 rounded font-semibold bg-cyan-900 p-3 text-white">
                    Members Info
                  </h3>
                  <div className="flex">
                    <div className="w-full">
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                        <div className="form-group2 pb-4 w-full  pr-10">
                          <label
                            htmlFor="nameOfTheCompany"
                            className="block mb-1 text-primary text-sm  sm:text-base font-semibold"
                          >
                            Name of the Company:
                          </label>
                          <input
                            type="text"
                            id="nameOfTheCompany"
                            name="nameOfTheCompany"
                            value={
                              formData?.memberInformation?.nameOfTheCompany
                            }
                            onChange={handleMemberInformationChange}
                            className="w-5/6 md:w-full lg:w-full rounded-md px-2 py-1 border-2 hover:border-2 hover:border-primary border-gray-300"
                          />
                        </div>
                        <div className="form-group2 pb-4 w-full md:pr-10">
                          <label
                            htmlFor="headOfficeAddress"
                            className="block mb-1 text-primary text-sm  sm:text-base font-semibold"
                          >
                            Head Office Address:
                          </label>
                          <input
                            type="text"
                            id="headOfficeAddress"
                            name="headOfficeAddress"
                            value={
                              formData?.memberInformation?.headOfficeAddress
                            }
                            onChange={handleMemberInformationChange}
                            className="w-3/4 md:w-full lg:w-full rounded-md px-2 py-1 border-2 hover:border-2 hover:border-primary border-gray-300"
                          />
                        </div>
                      </div>
                      <div className="grid grid-cols-1 sm:grid-cols-4 gap-2">
                        <div className="form-group2 pb-4 w-full  md:pr-10">
                          <label
                            htmlFor="country"
                            className="block mb-1 text-primary text-sm  sm:text-base font-semibold"
                          >
                            Country:
                          </label>
                          <CountryDropdown
                            id="country"
                            name="country"
                            value={formData?.memberInformation?.country}
                            onChange={(val) =>
                              handleCountryChangeoptionmem(val)
                            }
                            className="w-full border border-gray-300 rounded-md p-2"
                            whitelist={["CA", "US"]}
                          />
                        </div>
                        <div className="form-group2 pb-4 w-full  md:pr-10">
                          <label
                            htmlFor="province"
                            className="block mb-1 text-primary text-sm  sm:text-base font-semibold"
                          >
                            Province:
                          </label>
                          <RegionDropdown
                            id="territory"
                            name="territory"
                            country={formData?.memberInformation?.country}
                            value={formData?.memberInformation.province}
                            onChange={(val) => handleRegionChangeptionmem(val)}
                            className="w-full border border-gray-300 rounded-md p-2"
                            disableWhenEmpty
                          />
                        </div>
                        <div className="form-group2 pb-4 w-full  md:pr-10">
                          <label
                            htmlFor="city"
                            className="block mb-1 text-primary text-sm  sm:text-base font-semibold"
                          >
                            Town/City:
                          </label>
                          <input
                            type="text"
                            id="city"
                            name="city"
                            value={formData?.memberInformation.city}
                            onChange={handleMemberInformationChange}
                            className="w-3/4 md:w-full lg:w-full rounded-md px-2 py-1 border-2 hover:border-2 hover:border-primary border-gray-300"
                          />
                        </div>

                        <div className="form-group2 pb-4 w-full pr-10">
                          <label
                            htmlFor="postal"
                            className="block mb-1 text-primary text-sm  sm:text-base font-semibold"
                          >
                            Postal Code:
                          </label>
                          <input
                            type="text"
                            id="postal"
                            name="postal"
                            value={formData?.memberInformation.postal}
                            onChange={(e) => {
                              validatePostalMemberInfoFn(e);
                              handleMemberInformationChange(e);
                            }}
                            className="w-5/6 md:w-full lg:w-full rounded-md px-2 py-1 border-2 hover:border-2 hover:border-primary border-gray-300"
                          />
                          <span className="text-red-900">
                            {validatePostalMemberInfo
                              ? validatePostalMemberInfo
                              : ""}
                          </span>
                        </div>
                      </div>
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                        <div className="form-group2 pb-4 w-full md:pr-10">
                          <label
                            htmlFor="telephone"
                            className="block mb-1 text-primary text-sm  sm:text-base font-semibold"
                          >
                            {" "}
                            Telephone:
                          </label>
                          <input
                            type="tel"
                            id="telephone"
                            name="telephone"
                            value={formData?.memberInformation.telephone}
                            maxLength={12}
                            onKeyDown={(e) => {
                              // Allow numeric characters, +, -, backspace, delete, arrow keys, and tab
                              if (
                                !/^[\d+-]$/.test(e.key) &&
                                e.key !== "Backspace" &&
                                e.key !== "Delete" &&
                                e.key !== "ArrowLeft" &&
                                e.key !== "ArrowRight" &&
                                e.key !== "Tab"
                              ) {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e) => {
                              validateTelephoneFn(e);
                              handleMemberInformationChange(e);
                            }}
                            className="w-3/4 md:w-full lg:w-full rounded-md px-2 py-1 border-2 hover:border-2 hover:border-primary border-gray-300"
                          />
                          <span className="text-red-900">
                            {validateTelephone ? validateTelephone : ""}
                          </span>
                        </div>
                        <div className="form-group2 w-full pb-4  pr-10">
                          <label
                            htmlFor="website"
                            className="block mb-1 text-primary text-sm  sm:text-base font-semibold"
                          >
                            Website:
                          </label>
                          <input
                            type="text"
                            id="website"
                            name="website"
                            value={formData?.memberInformation.website}
                            onChange={(e) => {
                              validateWebsiteFn(e);
                              handleMemberInformationChange(e);
                            }}
                            className="w-5/6 md:w-full lg:w-full rounded-md px-2 py-1 border-2 hover:border-2 hover:border-primary border-gray-300"
                          />
                          <span className="text-red-900">
                            {validateWebsite ? validateWebsite : ""}
                          </span>
                        </div>
                      </div>
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                        <div className="form-group2 w-full pb-4  pr-10">
                          <label
                            htmlFor="fax"
                            className="block mb-1 text-primary text-sm  sm:text-base font-semibold"
                          >
                            Fax:
                          </label>
                          <input
                            type="tel"
                            id="fax"
                            name="fax"
                            maxLength={12}
                            pattern="[0-9]*"
                            onKeyDown={(e) => {
                              // Allow numeric characters, +, -, backspace, delete, arrow keys, and tab
                              if (
                                !/^[\d+-]$/.test(e.key) &&
                                e.key !== "Backspace" &&
                                e.key !== "Delete" &&
                                e.key !== "ArrowLeft" &&
                                e.key !== "ArrowRight" &&
                                e.key !== "Tab"
                              ) {
                                e.preventDefault();
                              }
                            }}
                            value={formData?.memberInformation.fax}
                            onChange={(e) => {
                              validateFaxFn(e);
                              handleMemberInformationChange(e);
                            }}
                            className="w-5/6 md:w-full lg:w-full rounded-md px-2 py-1 border-2 hover:border-2 hover:border-primary border-gray-300"
                          />
                          <span className="text-red-900">
                            {validateFax ? validateFax : ""}
                          </span>
                        </div>
                        <div className="form-group2 w-full pb-4  pr-10">
                          <label
                            htmlFor="emailAddress"
                            className="block mb-1 text-primary text-sm  sm:text-base font-semibold"
                          >
                            Email:
                          </label>
                          <input
                            type="text"
                            id="emailAddress"
                            name="emailAddress"
                            value={formData?.memberInformation.emailAddress}
                            onChange={(e) => {
                              validateEmailMemberInfo(e);
                              handleMemberInformationChange(e);
                            }}
                            className="w-5/6 md:w-full lg:w-full rounded-md px-2 py-1 border-2 hover:border-2 hover:border-primary border-gray-300"
                          />
                          <span className="text-red-900">
                            {validationEmailMemberInfo
                              ? validationEmailMemberInfo
                              : ""}
                          </span>
                        </div>
                      </div>
                      <h3 className=" mb-5 rounded font-semibold bg-cyan-900 p-3 text-white">
                        Company Information
                      </h3>
                      <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">
                        <div className="form-group2 w-full pb-4  pr-10">
                          <div>
                            {/* ------------------ */}
                            <label
                              htmlFor="companyLogo"
                              className="block text-gray-700 text-sm font-bold mb-2"
                            >
                              Comapny Logo:
                            </label>
                            <input
                              type="file"
                              id="companyLogo"
                              name="companyLogo"
                              accept="image/*"
                              onChange={handleImageUpload}
                              className="hidden"
                            />
                            <label
                              htmlFor="companyLogo"
                              className="cursor-pointer"
                            >
                              <div className="w-full h-40 border rounded-md flex items-center justify-center bg-gray-100">
                                {formData?.memberInformation.companyLogo ? (
                                  <img
                                    src={`${formData?.memberInformation.companyLogo}`}
                                    alt="Logo Preview"
                                    className="object-contain w-full h-full rounded-md"
                                  />
                                ) : (
                                  <span className="text-gray-500">
                                    Upload Logo
                                  </span>
                                )}
                              </div>
                            </label>
                          </div>
                        </div>
                        <div className="form-group2 w-full pb-4  pr-10">
                          <label
                            htmlFor="companyCategory"
                            className="block mb-1 text-primary text-sm  sm:text-base font-semibold"
                          >
                            Company Catgory:
                          </label>
                          <input
                            type="text"
                            id="companyCategory"
                            name="companyCategory"
                            value={formData?.memberInformation.companyCategory}
                            onChange={handleMemberInformationChange}
                            className="w-5/6 md:w-full lg:w-full rounded-md px-2 py-1 border-2 hover:border-2 hover:border-primary border-gray-300"
                          />
                        </div>
                        <div className="form-group2 w-full pb-4  pr-10">
                          <label
                            htmlFor="companyDescription"
                            className="block mb-1 text-primary text-sm  sm:text-base font-semibold"
                          >
                            Company Description:
                          </label>
                          <textarea
                            type="text"
                            id="companyDescription"
                            name="companyDescription"
                            value={
                              formData?.memberInformation.companyDescription
                            }
                            onChange={handleMemberInformationChange}
                            className="w-5/6 md:w-full lg:w-full rounded-md px-2 py-1 border-2 hover:border-2 hover:border-primary border-gray-300"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full step">
                  <h3 className=" mb-5 rounded font-semibold bg-cyan-900 p-3 text-white">
                    Contact Information
                  </h3>
                  <div className="flex">
                    <div className="w-full">
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                        <div className="form-group2 pb-4 w-full pr-10">
                          <label
                            htmlFor="firstName"
                            className="block mb-1 text-primary text-sm  sm:text-base font-semibold"
                          >
                            First Name:
                          </label>
                          <input
                            type="text"
                            id="firstName"
                            name="firstName"
                            value={formData?.contactInfo.firstName}
                            onChange={handlecontactInfoChange}
                            className="w-5/6 md:w-full lg:w-full rounded-md px-2 py-1 border-2 hover:border-2 hover:border-primary border-gray-300"
                          />
                        </div>
                        <div className="form-group2 pb-4 w-full pr-10">
                          <label
                            htmlFor="lastName"
                            className="block mb-1 text-primary text-sm  sm:text-base font-semibold"
                          >
                            Last Name:
                          </label>
                          <input
                            type="text"
                            id="lastName"
                            name="lastName"
                            value={formData?.contactInfo.lastName}
                            onChange={handlecontactInfoChange}
                            className="w-5/6 md:w-full lg:w-full rounded-md px-2 py-1 border-2 hover:border-2 hover:border-primary border-gray-300"
                          />
                        </div>
                      </div>
                      <div className="grid grid-cols-1 sm:grid-cols-1 gap-2">
                        <div className="form-group2 pb-4 w-full  pr-10">
                          <label
                            htmlFor="currentAddress"
                            className="block mb-1 text-primary text-sm  sm:text-base font-semibold"
                          >
                            Current Address:
                          </label>
                          <input
                            type="text"
                            id="currentAddress"
                            name="currentAddress"
                            value={formData?.contactInfo.currentAddress}
                            onChange={handlecontactInfoChange}
                            className="w-5/6 md:w-full lg:w-full rounded-md px-2 py-1 border-2 hover:border-2 hover:border-primary border-gray-300"
                          />
                        </div>
                      </div>
                      <div className="grid grid-cols-1 sm:grid-cols-4 gap-2">
                        <div className="form-group2 pb-4  pr-10">
                          <label
                            htmlFor="country"
                            className="block mb-1 text-primary text-sm  sm:text-base font-semibold"
                          >
                            Country:
                          </label>
                          <CountryDropdown
                            id="country"
                            name="country"
                            value={formData?.contactInfo.country}
                            onChange={(val) => handleCountryChangeoption(val)}
                            className="w-full border border-gray-300 rounded-md p-2"
                            whitelist={["CA", "US"]}
                          />
                        </div>

                        <div className="form-group2 w-full pb-4  pr-10">
                          <label
                            htmlFor="territory"
                            className="block mb-1 text-primary text-sm  sm:text-base font-semibold"
                          >
                            Province/Territory:
                          </label>
                          <RegionDropdown
                            id="territory"
                            name="territory"
                            country={formData?.contactInfo.country}
                            value={formData?.contactInfo.territory}
                            onChange={(val) => handleRegionChangeption(val)}
                            className="w-full border border-gray-300 rounded-md p-2"
                            disableWhenEmpty
                          />
                        </div>
                        <div className="form-group2 w-full pb-4  pr-10">
                          <label
                            htmlFor="city"
                            className="block mb-1 text-primary text-sm  sm:text-base font-semibold"
                          >
                            Town/City:
                          </label>
                          <input
                            type="text"
                            id="city"
                            name="city"
                            value={formData?.contactInfo.city}
                            onChange={handlecontactInfoChange}
                            className="w-5/6 md:w-full lg:w-full rounded-md px-2 py-1 border-2 hover:border-2 hover:border-primary border-gray-300"
                          />
                        </div>

                        <div className="form-group2 pb-4  pr-10">
                          <label
                            htmlFor="postal"
                            className="block mb-1 text-primary text-sm  sm:text-base font-semibold"
                          >
                            Postal Code:
                          </label>
                          <input
                            type="text"
                            id="postal"
                            name="postal"
                            maxLength={10}
                            value={formData?.contactInfo.postal}
                            onChange={(e) => {
                              validatePostalContactInfoFn(e);
                              handlecontactInfoChange(e);
                            }}
                            className="w-5/6 md:w-full lg:w-full rounded-md px-2 py-1 border-2 hover:border-2 hover:border-primary border-gray-300"
                          />
                          <span className="text-red-900">
                            {validatePostalContactInfo
                              ? validatePostalContactInfo
                              : ""}
                          </span>
                        </div>
                      </div>
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                        <div className="form-group2 pb-4 w-full pr-10">
                          <label
                            htmlFor="cellPhone"
                            className="block mb-1 text-primary text-sm  sm:text-base font-semibold"
                          >
                            Cell Phone:
                          </label>
                          <input
                            type="text"
                            id="cellPhone"
                            name="cellPhone"
                            maxLength={12}
                            onKeyDown={(e) => {
                              // Allow numeric characters, +, -, backspace, delete, arrow keys, and tab
                              if (
                                !/^[\d+-]$/.test(e.key) &&
                                e.key !== "Backspace" &&
                                e.key !== "Delete" &&
                                e.key !== "ArrowLeft" &&
                                e.key !== "ArrowRight" &&
                                e.key !== "Tab"
                              ) {
                                e.preventDefault();
                              }
                            }}
                            value={formData?.contactInfo.cellPhone}
                            onChange={(e) => {
                              validateCellPhoneFn(e);
                              handlecontactInfoChange(e);
                            }}
                            className="w-5/6 md:w-full lg:w-full rounded-md px-2 py-1 border-2 hover:border-2 hover:border-primary border-gray-300"
                          />
                          <span className="text-red-900">
                            {validateCellPhone ? validateCellPhone : ""}
                          </span>
                        </div>
                        <div className="form-group2 pb-4 w-full pr-10">
                          <label
                            htmlFor="homePhone"
                            className="block mb-1 text-primary text-sm  sm:text-base font-semibold"
                          >
                            Home Phone:
                          </label>
                          <input
                            type="text"
                            id="homePhone"
                            name="homePhone"
                            maxLength={12}
                            onKeyDown={(e) => {
                              // Allow numeric characters, +, -, backspace, delete, arrow keys, and tab
                              if (
                                !/^[\d+-]$/.test(e.key) &&
                                e.key !== "Backspace" &&
                                e.key !== "Delete" &&
                                e.key !== "ArrowLeft" &&
                                e.key !== "ArrowRight" &&
                                e.key !== "Tab"
                              ) {
                                e.preventDefault();
                              }
                            }}
                            value={formData?.contactInfo.homePhone}
                            onChange={(e) => {
                              validateHomePhoneFn(e);
                              handlecontactInfoChange(e);
                            }}
                            className="w-5/6 md:w-full lg:w-full rounded-md px-2 py-1 border-2 hover:border-2 hover:border-primary border-gray-300"
                          />
                          <span className="text-red-900">
                            {validateHomePhone ? validateHomePhone : ""}
                          </span>
                        </div>
                      </div>
                      <h3 className=" mb-5 rounded font-semibold bg-cyan-900 p-3 text-white">
                        Account Information
                      </h3>
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                        <div className="form-group2 pb-4 w-full pr-10">
                          <label
                            htmlFor="userName"
                            className="block mb-1 text-primary text-sm  sm:text-base font-semibold"
                          >
                            Username:
                          </label>
                          <input
                            type="text"
                            id="userName"
                            name="userName"
                            value={formData?.contactInfo.userName}
                            onChange={handlecontactInfoChange}
                            className="w-5/6 md:w-full lg:w-full rounded-md px-2 py-1 border-2 hover:border-2 hover:border-primary border-gray-300"
                          />
                        </div>
                        <div className="form-group2 pb-4 w-full  pr-10">
                          <label
                            htmlFor="emailAddress"
                            className="block mb-1 text-primary text-sm  sm:text-base font-semibold"
                          >
                            Email:
                          </label>
                          <input
                            type="text"
                            id="emailAddress"
                            name="emailAddress"
                            value={formData?.contactInfo.emailAddress}
                            onChange={(e) => {
                              validateEmailContactInfo(e);
                              handlecontactInfoChange(e);
                            }}
                            className="w-5/6 md:w-full lg:w-full rounded-md px-2 py-1 border-2 hover:border-2 hover:border-primary border-gray-300"
                          />
                          <span className="text-red-900">
                            {validationEmailContactInfo
                              ? validationEmailContactInfo
                              : ""}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="step">
                  <h3 className=" mb-5 rounded font-semibold bg-cyan-900 p-3 text-white">
                    Membership Package Selection
                  </h3>
                  <p className="text-lg sm:text-2xl font-semibold text-primary">
                    Selected Membership Package:
                  </p>
                  <div className="flex flex-col sm:mt-8">
                    {formData?.packageInformation?.paymentSuccess ? (
                      <>
                        <div className="border p-4 rounded-xl shadow-xl border-green-500 w-fit">
                          <h3 class="text-xl font-bold mb-4">
                            {formData?.packageInformation.packageId.name}
                          </h3>
                          <p class="text-gray-600 mb-4">
                            {formData?.packageInformation.packageId.description}
                          </p>
                          <p class="text-lg font-semibold mb-4">
                            Price: $
                            {formData?.packageInformation.packageId.price}
                          </p>
                          <span class="bg-blue-500 capitalize text-white py-1 px-4 rounded-full text-sm mb-4 inline-block">
                            {formData?.packageInformation.packageId.tag}
                          </span>
                          <div>
                            {formData?.packageInformation?.validTillDate && (
                              <span className="text-red-700 px-2">
                                {daysBetweenDates(
                                  formData?.packageInformation?.validTillDate
                                )}{" "}
                                days left
                              </span>
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <p className="text-red-500">Payment not Completed</p>
                    )}
                  </div>
                </div>
              </>
            )}
            <div className="text-center my-2">
              {admin ? (
                <>
                  <button
                    onClick={(e) => {
                      handleUpdateAdminProfile(e);
                    }}
                    className="bg-cyan-900 mx-2 text-xs md:text-base lg:text-base rounded-sm text-white p-2 md:p-2 lg:p-2"
                  >
                    Update Profile
                  </button>
                  <button
                    onClick={(e) => {
                      handleChangeAdminPassword(e);
                    }}
                    className="bg-cyan-900 mx-2 text-xs md:text-base lg:text-base rounded-sm text-white p-2 md:p-2 lg:p-2"
                  >
                    Change Password
                  </button>
                </>
              ) : (
                <>
                  <button
                    onClick={(e) => handleSubmit(e)}
                    className="bg-cyan-900 text-xs md:text-base lg:text-base rounded-sm text-white p-2 md:p-2 lg:p-2"
                  >
                    Save Changes
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Profile;
