import { RxDashboard } from "react-icons/rx";
import { FiUser, FiUsers } from "react-icons/fi";
import { IoBusiness, IoSettingsOutline } from "react-icons/io5";
import {
  MdContacts,
  MdCategory,
  MdAttachMoney,
  MdOutlineEmail,
  MdEvent,
} from "react-icons/md";
import { LiaBlogSolid } from "react-icons/lia";
import { SiVlcmediaplayer } from "react-icons/si";
import { BiTransfer } from "react-icons/bi";

export const navigation = [
  {
    label: "Dashboard",
    icon: RxDashboard,
    path: "/dashboard",
  },
  {
    label: "Profile",
    icon: FiUser,
    path: "/profile",
  },
  {
    label: "Users",
    icon: FiUsers,
    path: "/members",
  },
  {
    label: "Business",
    icon: IoBusiness,
    path: "/business",
  },
  {
    label: "Business Categories",
    icon: MdCategory,
    path: "/business-categories",
  },
  {
    label: "Packages",
    icon: MdAttachMoney,
    path: "/packages",
  },
  {
    label: "Contacts",
    icon: MdContacts,
    path: "/contacts",
  },
  
  {
    label: "Blogs",
    icon: LiaBlogSolid,
    path: "/blogs",
  },
  {
    label: "Blogs Categories",
    icon: MdCategory,
    path: "/blogs-categories",
  },
  // {
  //   label: "Media Blogs",
  //   icon: SiVlcmediaplayer,
  //   path: "/media-blogs",
  // },
  {
    label: "Event",
    icon: MdEvent,
    path: "/event",
  },
  {
    label: "Transactions",
    icon: BiTransfer,
    path: "/transactions",
  },
];
export const memberNavigation = [
  {
    label: "Dashboard",
    icon: RxDashboard,
    path: "/user/dashboard",
  },
  {
    label: "Profile",
    icon: FiUser,
    path: "/user/profile",
  },
  {
    label: "Business",
    icon: IoBusiness,
    path: "/user/business",
  },
  {
    label: "Events",
    icon: MdEvent,
    path: "/user/events",
  },
  // {
  //   label: "Setting",
  //   icon: IoSettingsOutline,
  //   path: "/user/setting",
  // },
  {
    label: "Subscription",
    icon: MdOutlineEmail,
    path: "/user/subscription",
  },
];
