import axios from "axios";
import { useState } from "react";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { API_HEADER, API_URL } from "../../../redux/slices/URL";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";

const EditUserListing = () => {
  const location = useLocation();
  const { row } = location.state;
  const [validationEmailMemberInfo, setValidationEmailMemberInfo] =
    useState("");
  const [validationEmailContactInfo, setValidationEmailContactInfo] =
    useState("");
  const [validateTelephone, setValidateTelephone] = useState("");
  const [validateFax, setValidateFax] = useState("");
  const [validateCellPhone, setValidateCellPhone] = useState("");
  const [validateHomePhone, setValidateHomePhone] = useState("");
  const [validateWebsite, setValidateWebsite] = useState("");
  const [validatePostalContactInfo, setValidatePostalContactInfo] =
    useState("");
  const [validatePostalMemberInfo, setValidatePostalMemberInfo] = useState("");

  // Create state variables to hold the edited data
  const [editedData, setEditedData] = useState(row);
  // ==================================Validations=====================================
  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };
  const validateEmailMemberInfo = debounce((e) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+$/;
    if (e.target.value.trim() === "") {
      setValidationEmailMemberInfo("");
    } else if (!regex.test(e.target.value)) {
      setValidationEmailMemberInfo("Invalid Email");
    } else {
      setValidationEmailMemberInfo("");
    }
  }, 1000);

  const validateEmailContactInfo = debounce((e) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+$/;
    if (e.target.value.trim() === "") {
      setValidationEmailContactInfo("");
    } else if (!regex.test(e.target.value)) {
      setValidationEmailContactInfo("Invalid Email");
    } else {
      setValidationEmailContactInfo("");
    }
  }, 1000);
  const validatePostalContactInfoFn = debounce((e) => {
    const regex = /^(?:\d{5}(?:-\d{4})?|\b[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d)$/;
    if (e.target.value.trim() === "") {
      setValidatePostalContactInfo("");
    } else if (!regex.test(e.target.value)) {
      setValidatePostalContactInfo("Invalid Postal Code");
    } else {
      setValidatePostalContactInfo("");
    }
  }, 1000);
  const validatePostalMemberInfoFn = debounce((e) => {
    const regex = /^(?:\d{5}(?:-\d{4})?|\b[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d)$/;
    if (e.target.value.trim() === "") {
      setValidatePostalMemberInfo("");
    } else if (!regex.test(e.target.value)) {
      setValidatePostalMemberInfo("Invalid Postal Code");
    } else {
      setValidatePostalMemberInfo("");
    }
  }, 1000);

  const validateTelephoneFn = debounce((e) => {
    const telephoneRegex = /^(\+?\d{2})?(\d{10})$/;

    if (e.target.value.trim() === "") {
      setValidateTelephone("");
    } else if (!telephoneRegex.test(e.target.value)) {
      setValidateTelephone("Invalid telephone");
    } else {
      setValidateTelephone("");
    }
  }, 1000);

  const validateFaxFn = debounce((e) => {
    const faxRegex = /^(\+?\d{2})?(\d{10})$/;
    if (e.target.value.trim() === "") {
      setValidateFax("");
    } else if (!faxRegex.test(e.target.value)) {
      setValidateFax("Invalid Fax");
    } else {
      setValidateFax("");
    }
  }, 1000);

  const validateCellPhoneFn = debounce((e) => {
    const cellPhoneRegex =
      /^(?:\+1)?(?:\d{10}|\d{3}-\d{3}-\d{4}|\(\d{3}\)\s*\d{3}-\d{4}|\d{3}\.\d{3}\.\d{4})$/;
    if (e.target.value.trim() === "") {
      setValidateCellPhone("");
    } else if (!cellPhoneRegex.test(e.target.value)) {
      setValidateCellPhone("Invalid CellPhone");
    } else {
      setValidateCellPhone("");
    }
  }, 1000);

  const validateHomePhoneFn = debounce((e) => {
    const homePhoneRegex = /^(\+?\d{2})?(\d{10})$/;
    if (e.target.value.trim() === "") {
      setValidateHomePhone("");
    } else if (!homePhoneRegex.test(e.target.value)) {
      setValidateHomePhone("Invalid HomePhone");
    } else {
      setValidateHomePhone("");
    }
  }, 1000);
  const validateWebsiteFn = debounce((e) => {
    const WebsiteRegex =
      /^(ftp|http|https):\/\/([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/;
    if (e.target.value.trim() === "") {
      setValidateWebsite("");
    } else if (!WebsiteRegex.test(e.target.value)) {
      setValidateWebsite("Invalid Website");
    } else {
      setValidateWebsite("");
    }
  }, 1000);

  // =====================================================================================

  const handlecontactInfoChange = (e) => {
    const { name, value } = e.target;
    setEditedData((prevData) => ({
      ...prevData,
      contactInfo: {
        ...prevData.contactInfo,
        [name]: value,
      },
    }));
  };
  const handleMemberInformationChange = (e) => {
    const { name, value } = e.target;
    setEditedData((prevData) => ({
      ...prevData,
      memberInformation: {
        ...prevData.memberInformation,
        [name]: value,
      },
    }));
  };
  const handleImageUpload = async (e) => {
    try {
      const formData = new FormData();
      formData.append("logo", e?.target?.files[0]);
      const imageUplaodResponse = await axios.post(
        `${API_URL}/members/logoUpload`,
        formData,
        API_HEADER
      );
      if (imageUplaodResponse.data.success) {
        const { name } = e.target;
        setEditedData((prevData) => ({
          ...prevData,
          memberInformation: {
            ...prevData.memberInformation,
            [name]: imageUplaodResponse?.data?.filePath,
          },
        }));

        toast.success("logo uploaded");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const handleCountryChangeoption = (val) => {
    setEditedData((prevFormData) => ({
      ...prevFormData,
      contactInfo: {
        ...prevFormData.contactInfo,
        country: val,
      },
    }));
  };
  const handleRegionChangeption = (val) => {
    setEditedData((prevFormData) => ({
      ...prevFormData,
      contactInfo: {
        ...prevFormData.contactInfo,
        territory: val,
      },
    }));
  };
  const handleCountryChangeoptionmem = (val) => {
    setEditedData((prevFormData) => ({
      ...prevFormData,
      memberInformation: {
        ...prevFormData.memberInformation,
        country: val,
      },
    }));
  };
  const handleRegionChangeptionmem = (val) => {
    setEditedData((prevFormData) => ({
      ...prevFormData,
      memberInformation: {
        ...prevFormData.memberInformation,
        province: val,
      },
    }));
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      validationEmailMemberInfo ||
      validationEmailContactInfo ||
      validateTelephone ||
      validateCellPhone ||
      validateFax ||
      validateHomePhone ||
      validateWebsite
    ) {
      toast.error("Fill Valid Details");
      return;
    }
    try {
      const response = await axios.put(
        `${API_URL}/members/${row._id}`,
        editedData,
        API_HEADER
      );
      if (response.data.success) {
        toast.success(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <>
      <div className="container mx-auto m-8">
        <div className="bg-white p-8 rounded shadow-md">
          <h2 className="text-3xl font-bold mb-6 text-primary-main">
            Edit User
          </h2>
          <form onSubmit={handleSubmit}>
            {/* Primary Contact Information */}
            <div className="mb-4">
              <h3 className="text-lg font-semibold mb-2">Primary Contact</h3>
              <div className="flex w-100 justify-between flex-col md:flex-row">
                <div className="w-full mx-2">
                  <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      First Name:
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      value={editedData.contactInfo.firstName}
                      onChange={handlecontactInfoChange}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Address:
                    </label>
                    <input
                      type="text"
                      name="currentAddress"
                      value={editedData.contactInfo.currentAddress}
                      onChange={handlecontactInfoChange}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Province/Territory:
                    </label>
                    {/* <input
                      type="text"
                      name="territory"
                      value={editedData.contactInfo.territory}
                      onChange={handlecontactInfoChange}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    /> */}
                    <RegionDropdown
                      id="territory"
                      name="territory"
                      country={editedData.contactInfo.country}
                      value={editedData.contactInfo.territory}
                      onChange={(val) => handleRegionChangeption(val)}
                      className="w-full border border-gray-300 rounded-md p-2"
                      disableWhenEmpty
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Cell Phone:
                    </label>
                    <input
                      type="text"
                      name="cellPhone"
                      maxLength={12}
                      onKeyDown={(e) => {
                        // Allow numeric characters, +, -, backspace, delete, arrow keys, and tab
                        if (
                          !/^[\d+-]$/.test(e.key) &&
                          e.key !== "Backspace" &&
                          e.key !== "Delete" &&
                          e.key !== "ArrowLeft" &&
                          e.key !== "ArrowRight" &&
                          e.key !== "Tab"
                        ) {
                          e.preventDefault();
                        }
                      }}
                      value={editedData.contactInfo.cellPhone}
                      onChange={(e) => {
                        validateCellPhoneFn(e);
                        handlecontactInfoChange(e);
                      }}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    <span className="text-red-900">
                      {validateCellPhone ? validateCellPhone : ""}
                    </span>
                  </div>
                  <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Email:
                    </label>
                    <input
                      type="text"
                      name="emailAddress"
                      value={editedData.contactInfo.emailAddress}
                      onChange={(e) => {
                        validateEmailContactInfo(e);
                        handlecontactInfoChange(e);
                      }}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    <span className="text-red-900">
                      {validationEmailContactInfo
                        ? validationEmailContactInfo
                        : ""}
                    </span>
                  </div>
                </div>
                <div className="w-full mx-2">
                  <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Last Name:
                    </label>
                    <input
                      type="text"
                      name="lastName"
                      value={editedData.contactInfo.lastName}
                      onChange={handlecontactInfoChange}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Country:
                    </label>
                    {/* <input
                      type="text"
                      name="country"
                      value={editedData.contactInfo.country}
                      onChange={handlecontactInfoChange}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    /> */}
                    <CountryDropdown
                      id="country"
                      name="country"
                      value={editedData.contactInfo.country}
                      onChange={(val) => handleCountryChangeoption(val)}
                      className="w-full border border-gray-300 rounded-md p-2"
                      whitelist={["CA", "US"]}
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Town/City:
                    </label>
                    <input
                      type="text"
                      name="city"
                      value={editedData.contactInfo.city}
                      onChange={handlecontactInfoChange}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Postal Code:
                    </label>
                    <input
                      type="text"
                      name="postal"
                      maxLength={10}
                      value={editedData.contactInfo.postal}
                      onChange={(e) => {
                        validatePostalContactInfoFn(e);
                        handlecontactInfoChange(e);
                      }}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    <span className="text-red-900">
                      {validatePostalContactInfo
                        ? validatePostalContactInfo
                        : ""}
                    </span>
                  </div>
                  <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Username:
                    </label>
                    <input
                      type="text"
                      name="userName"
                      value={editedData.contactInfo.userName}
                      onChange={handlecontactInfoChange}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                  </div>
                </div>
              </div>
              {/* Add similar fields for other primary contact information */}
            </div>

            {/* Member Information */}
            <div className="mb-4">
              <h3 className="text-lg font-semibold mb-2">Member Information</h3>
              <div className="flex w-100 justify-between flex-col md:flex-row">
                <div className="w-full mx-2">
                  <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Company Name:
                    </label>
                    <input
                      type="text"
                      name="nameOfTheCompany"
                      value={editedData.memberInformation.nameOfTheCompany}
                      onChange={handleMemberInformationChange}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                  </div>

                  <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Country:
                    </label>
                    {/* <input
                      type="text"
                      name="country"
                      value={editedData.memberInformation.country}
                      onChange={handleMemberInformationChange}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    /> */}
                    <CountryDropdown
                      id="country"
                      name="country"
                      value={editedData.memberInformation.country}
                      onChange={(val) => handleCountryChangeoptionmem(val)}
                      className="w-full border border-gray-300 rounded-md p-2"
                      whitelist={["CA", "US"]}
                    />
                  </div>

                  <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      City:
                    </label>
                    <input
                      type="text"
                      name="city"
                      value={editedData.memberInformation.city}
                      onChange={handleMemberInformationChange}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Telephone:
                    </label>
                    <input
                      type="tel"
                      name="telephone"
                      maxLength={12}
                      onKeyDown={(e) => {
                        // Allow numeric characters, +, -, backspace, delete, arrow keys, and tab
                        if (
                          !/^[\d+-]$/.test(e.key) &&
                          e.key !== "Backspace" &&
                          e.key !== "Delete" &&
                          e.key !== "ArrowLeft" &&
                          e.key !== "ArrowRight" &&
                          e.key !== "Tab"
                        ) {
                          e.preventDefault();
                        }
                      }}
                      value={editedData.memberInformation.telephone}
                      onChange={(e) => {
                        validateTelephoneFn(e);
                        handleMemberInformationChange(e);
                      }}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    <span className="text-red-900">
                      {validateTelephone ? validateTelephone : ""}
                    </span>
                  </div>
                  <div>
                    {/* ------------------ */}
                    <label
                      htmlFor="companyLogo"
                      className="block text-gray-700 text-sm font-bold mb-2"
                    >
                      Comapny Logo:
                    </label>
                    <input
                      type="file"
                      id="companyLogo"
                      name="companyLogo"
                      accept="image/*"
                      onChange={handleImageUpload}
                      className="hidden"
                    />
                    <label htmlFor="companyLogo" className="cursor-pointer">
                      <div className="w-full h-40 border rounded-md flex items-center justify-center bg-gray-100">
                        {editedData.memberInformation.companyLogo ? (
                          <img
                            src={`${editedData.memberInformation.companyLogo}`}
                            alt="Logo Preview"
                            className="object-contain w-full h-full rounded-md"
                          />
                        ) : (
                          <span className="text-gray-500">Upload Logo</span>
                        )}
                      </div>
                    </label>
                  </div>
                  {/* ------------------ */}
                </div>
                <div className="w-full mx-2">
                  <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Head Office Address:
                    </label>
                    <input
                      type="text"
                      name="headOfficeAddress"
                      value={editedData.memberInformation.headOfficeAddress}
                      onChange={handleMemberInformationChange}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Province:
                    </label>
                    {/* <input
                      type="text"
                      name="province"
                      value={editedData.memberInformation.province}
                      onChange={handleMemberInformationChange}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    /> */}
                    <RegionDropdown
                      id="territory"
                      name="territory"
                      country={editedData.memberInformation.country}
                      value={editedData.memberInformation.province}
                      onChange={(val) => handleRegionChangeptionmem(val)}
                      className="w-full border border-gray-300 rounded-md p-2"
                      disableWhenEmpty
                    />
                  </div>

                  <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Postal Code:
                    </label>
                    <input
                      type="text"
                      name="postal"
                      maxLength={10}
                      value={editedData.memberInformation.postal}
                      onChange={(e) => {
                        validatePostalMemberInfoFn(e);
                        handleMemberInformationChange(e);
                      }}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    <span className="text-red-900">
                      {validatePostalMemberInfo ? validatePostalMemberInfo : ""}
                    </span>
                  </div>

                  <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Email:
                    </label>
                    <input
                      type="email"
                      name="emailAddress"
                      value={editedData.memberInformation.emailAddress}
                      onChange={(e) => {
                        validateEmailMemberInfo(e);
                        handleMemberInformationChange(e);
                      }}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    <span className="text-red-900">
                      {validationEmailMemberInfo
                        ? validationEmailMemberInfo
                        : ""}
                    </span>
                  </div>
                  <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Fax:
                    </label>
                    <input
                      type="tel"
                      name="fax"
                      maxLength={12}
                      pattern="[0-9]*"
                      onKeyDown={(e) => {
                        // Allow numeric characters, +, -, backspace, delete, arrow keys, and tab
                        if (
                          !/^[\d+-]$/.test(e.key) &&
                          e.key !== "Backspace" &&
                          e.key !== "Delete" &&
                          e.key !== "ArrowLeft" &&
                          e.key !== "ArrowRight" &&
                          e.key !== "Tab"
                        ) {
                          e.preventDefault();
                        }
                      }}
                      value={editedData.memberInformation.fax}
                      onChange={(e) => {
                        validateFaxFn(e);
                        handleMemberInformationChange(e);
                      }}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    <span className="text-red-900">
                      {validateFax ? validateFax : ""}
                    </span>
                  </div>
                  <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Website:
                    </label>
                    <input
                      type="text"
                      name="website"
                      value={editedData.memberInformation.website}
                      onChange={(e) => {
                        validateWebsiteFn(e);
                        handleMemberInformationChange(e);
                      }}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    <span className="text-red-900">
                      {validateWebsite ? validateWebsite : ""}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* Package Information */}
            <div className="mb-4">
              <h3 className="text-lg font-semibold mb-2">
                Package Information
              </h3>
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Package Name:
                </label>
                <input
                  type="text"
                  name="packageName"
                  value={editedData.packageInformation.packageId.name}
                  // onChange={handleInputChange}
                  disabled
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Description:
                </label>
                <input
                  type="text"
                  name="description"
                  value={editedData.packageInformation.packageId.description}
                  // onChange={handleInputChange}
                  disabled
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Price:
                </label>
                <input
                  type="text"
                  name="price"
                  value={editedData.packageInformation.packageId.price}
                  // onChange={handleInputChange}
                  disabled
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
            </div>

            {/* User Status */}
            <div className="mb-4">
              <h3 className="text-lg font-semibold mb-2">User Status</h3>
              <p>
                <span className="font-semibold text-gray-600">Status:</span>{" "}
                <span
                  className={row.status ? "text-green-600" : "text-red-600"}
                >
                  {row.status ? "Active" : "Inactive"}
                </span>
              </p>
              {/* Add form fields for user status */}
            </div>

            {/* Submit button */}
            <div className="flex items-center justify-center">
              <button
                type="submit"
                className="bg-primary-main text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditUserListing;
