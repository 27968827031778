import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import { createEvent } from "../../redux/slices/EventSlice";

const AddEvent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    location: "",
    date: new Date(),
  });

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Format month and day to have leading zeros if necessary
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }

    return `${year}-${month}-${day}`;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      dispatch(createEvent({ formData }))
        .then((res) => {
          if (res.payload.success) {
            toast.success("Added");
          }
        })
        .catch((error) => toast.error(error.message));
      navigate("/event");
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <>
      <div class=" mx-auto bg-white p-8 rounded-md shadow-md">
        <h2 class="text-2xl font-semibold mb-4">Add Event</h2>
        <form onSubmit={handleSubmit}>
          <div class="mb-4 ">
            <label for="title" class="block text-sm font-medium text-gray-600">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              class="mt-1 p-2 w-full border rounded-md"
              onChange={handleChange}
            />
          </div>

          <div class="mb-4">
            <label
              for="content"
              class="block text-sm font-medium text-gray-600"
            >
              Description
            </label>
            {/* <ReactQuill
              value={formData.description}
              modules={modules}
              formats={formats}
              onChange={(e) => handleTextChange(e)}
            /> */}
            <textarea
              id="description"
              name="description"
              rows="4"
              value={formData.description}
              class="mt-1 p-2 w-full border rounded-md"
              onChange={handleChange}
            ></textarea>
          </div>
          <div class="mb-4 ">
            <label for="title" class="block text-sm font-medium text-gray-600">
              Location
            </label>
            <input
              type="text"
              id="location"
              name="location"
              value={formData.location}
              class="mt-1 p-2 w-full border rounded-md"
              onChange={handleChange}
            />
          </div>

          <div class="mb-4 ">
            <label for="title" class="block text-sm font-medium text-gray-600">
              Date
            </label>
            <input
              type="date"
              id="date"
              name="date"
              value={formData.date}
              class="mt-1 p-2 w-full border rounded-md"
              onChange={handleChange}
              min={getTodayDate()}
            />
          </div>

          <div>
            <button
              type="submit"
              class="bg-green-500 text-white py-2 px-4 rounded-md"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddEvent;
