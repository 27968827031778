import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL, API_HEADER } from "./URL";

export const fetchPackage = createAsyncThunk(
  "package/fetchPackage",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_URL}/package`, API_HEADER);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const addPackage = createAsyncThunk(
  "package/addPackage",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_URL}/package`, data, API_HEADER);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const editPackage = createAsyncThunk(
  "package/editPackage",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${API_URL}/package/${data._id}`,
        data,
        API_HEADER
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deletePackage = createAsyncThunk(
  "package/deletePackage",
  async ({ packageId }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${API_URL}/package/${packageId}`,
        API_HEADER,
        packageId
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const packageSlice = createSlice({
  name: "package",

  initialState: {
    packages: [],
    loading: false,
  },
  reducers: {},
  extraReducers: {
    [fetchPackage.pending]: (state) => {
      state.loading = true;
    },
    [fetchPackage.fulfilled]: (state, action) => {
      state.packages = action.payload.packages;
      state.loading = false;
    },
    [fetchPackage.rejected]: (state) => {
      state.loading = false;
    },

    [addPackage.fulfilled]: (state, action) => {
      state.packages.push(action.payload.package);
      state.loading = false;
    },
    [editPackage.fulfilled]: (state, action) => {
    //   console.log(action.payload.updatedPackage);
      state.loading = false;
    },

    [deletePackage.fulfilled]: (state, action) => {
      state.packages = state.packages.filter(
        (item) => item._id !== action.payload.packageDeletedId
      );
      state.loading = false;
    },
  },
});
// export const {} = authSlice.actions;

export default packageSlice.reducer;
