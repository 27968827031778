import React, { useEffect, useState } from "react";
// import UserLkisting from "./UserLkisting";
import { useNavigate } from "react-router-dom";
import { FaPencil } from "react-icons/fa6";
import { FaTrashAlt } from "react-icons/fa";
import ShowDialogBox from "../../components/ShowDialogBox/ShowDialogBox";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchBusiness,
  deleteBusiness,
  changeStatusBusiness,
} from "../../redux/slices/businessSlice";
import TransactionListing from "./transactionListing";
import { fetchTransactions } from "../../redux/slices/TransactionSlice";

const TransactionListingWrapper = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { transactions, loading } = useSelector((state) => state.transaction);

  useEffect(() => {
    dispatch(fetchTransactions());
  }, []);

  const handleStatusChange = (e, id) => {
    let data = {
      id: id,
      value: e.target.value,
    };
    dispatch(changeStatusBusiness({ data }));
  };

  const columns = [
    {
      field: "transactionId",
      headerName: "Transaction Id",
      flex: "flex-[1_1_0%]",
      renderCell: (row) => <span> {`${row.transactionId}`} </span>,
    },
    {
      field: "amount",
      headerName: "Transaction Amount",
      flex: "flex-[1.5_1.5_0%]",
      renderCell: (row) => {
        return <span> {row.amount} </span>;
      },
    },
    {
      field: "transactionDate",
      headerName: "Transaction",
      flex: "flex-[1_1_0%]",
      renderCell: (row) => {
        return (
          <span>
            {" "}
            {`${new Date(row.transactionDate).toLocaleDateString()}`}{" "}
          </span>
        );
      },
    },
    {
      field: "user",
      headerName: "User Name",
      flex: "flex-[1_1_0%]",
      renderCell: (row) => {
        return <span> {`${row.userId?.contactInfo?.firstName}`} </span>;
      },
    },
    // {
    //   field: "approvedByAdmin",
    //   headerName: "Status",
    //   flex: "flex-[1_1_0%]",
    //   renderCell: (row) => {
    //     return (
    //       <span>
    //         <select
    //           onChange={(e) => handleStatusChange(e, row._id)}
    //           value={row.approvedByAdmin}
    //         >
    //           <option value="approved">Approved</option>
    //           <option value="rejected">Rejected</option>
    //           <option value="pending">Pending</option>
    //         </select>
    //       </span>
    //     );
    //   },
    // },
    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   flex: "flex-[0.5_0.5_0%]",
    //   renderCell: (row) => (
    //     <>
    //       <FaPencil
    //         onClick={() => handleEdit(row)}
    //         style={{ cursor: "pointer", marginRight: "8px" }}
    //       />
    //       <FaTrashAlt
    //         onClick={() => handleDelete(row)}
    //         style={{ cursor: "pointer", marginRight: "8px" }}
    //       />
    //     </>
    //   ),
    //   align: "end",
    // },
  ];
  const handleBusinessDelete = (businessId) => {
    dispatch(deleteBusiness({ businessId }));
  };

  const handleDelete = (row) => {
    ShowDialogBox({
      title: "Delete Item",
      text: "Are you sure you want to delete this item?",
      icon: "warning",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel", // Provide cancel button text
      successTitle: "Deleted!",
      successText: "The item has been deleted successfully.",
      itemId: row._id,
      deleteItem: handleBusinessDelete,
    });
  };
  const handleEdit = (row) => {
    navigate(`edit/${row.id}`);
  };
  return (
    <TransactionListing
      rows={transactions}
      columns={columns}
      loading={loading}
    />
  );
};

export default TransactionListingWrapper;
