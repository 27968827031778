import React, { useEffect, useState } from "react";
// import UserLkisting from "./UserLkisting";
import { useNavigate } from "react-router-dom";
import { FaPencil } from "react-icons/fa6";
import { FaTrashAlt } from "react-icons/fa";
import ShowDialogBox from "../../../components/ShowDialogBox/ShowDialogBox";
import { useDispatch, useSelector } from "react-redux";
import { deleteBusiness } from "../../../redux/slices/businessSlice";
import MemBusinessListing from "./memBusinessListing";
import { fetchBusinessOfMember } from "../../../redux/slices/businessSlice";

const MemBusinessListingWrapper = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { business, loading } = useSelector((state) => state.business);

  useEffect(() => {
    dispatch(fetchBusinessOfMember());
  }, []);

  const columns = [
    {
      field: "name",
      headerName: "Business Name",
      flex: "flex-1 sm:flex-1 md:flex-0",
      renderCell: (row) => <span> {`${row.name}`} </span>,
    },
    {
      field: "email",
      headerName: "Email Address",
      flex: "flex-1.5 sm:flex-1.5 md:flex-0",
      renderCell: (row) => {
        return <span> {row.email} </span>;
      },
    },
    {
      field: "mobile",
      headerName: "Mobile no.",
      flex: "flex-1 sm:flex-1 md:flex-0",
      renderCell: (row) => {
        return <span> {`${row.phone}`} </span>;
      },
    },
    {
      field: "category",
      headerName: "Business Category",
      flex: "flex-1 sm:flex-1 md:flex-0",
      renderCell: (row) => {
        return <span> {`${row.category}`} </span>;
      },
    },
    {
      field: "approvedByAdmin",
      headerName: "Status",
      flex: "flex-1 sm:flex-1 md:flex-0",
      renderCell: (row) => {
        return <span>{row.approvedByAdmin}</span>;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: "flex-0.5 sm:flex-0.5 md:flex-0",
      renderCell: (row) => (
        <>
          <FaPencil
            onClick={() => handleEdit(row)}
            style={{ cursor: "pointer", marginRight: "8px" }}
          />
          <FaTrashAlt
            onClick={() => handleDelete(row)}
            style={{ cursor: "pointer", marginRight: "8px" }}
          />
        </>
      ),
      align: "end",
    },
  ];
  const handleBusinessDelete = (businessId) => {
    dispatch(deleteBusiness({ businessId }));
  };

  const handleDelete = (row) => {
    ShowDialogBox({
      title: "Delete Item",
      text: "Are you sure you want to delete this item?",
      icon: "warning",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel", // Provide cancel button text
      successTitle: "Deleted!",
      successText: "The item has been deleted successfully.",
      itemId: row._id,
      deleteItem: handleBusinessDelete,
    });
  };
  const handleEdit = (row) => {
    navigate(`edit`, {
      state: {
        row: row,
      },
    });
  };
  return (
    <MemBusinessListing rows={business} columns={columns} loading={loading} />
  );
};

export default MemBusinessListingWrapper;
